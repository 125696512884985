import React from "react";

import { 
  Page,
  Card,
  Grid,
} from 'tabler-react';

import { Image } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import SiteWrapper from '../../SiteWrapper';

const imageStyle = {
  display: 'block',
    margin: '0 auto',
};

function SensPak() {
  return(
	  <SiteWrapper>
	  	<Page.Content>
	  		<Card statusColor="green">
	  		<Card.Header>
          <Card.Title><b>SENSPAK</b></Card.Title>
          </Card.Header>
          <Card.Body>
          <div id="senspak" className="container-fluid">
              <p>The SensPak is a low-cost, modular, in-situ water quality data acquisition instrument equipped with near real-time telemetry. The instrument is able to acquire data for the following parameters: pH, Temperature, Dissolved Oxygen, Conductivity and Chlorophyll-a.</p>
              <br />
              <img src="./images/senspak_image_1.png" style={imageStyle}/>
              <br />
              <p>It has three (3) main modules:</p>
              <p>A.	Battery Module – This provides the power to the instrument. It contains the charging port and indicates the battery level.</p>
              <p>B.	Electronics and Communication Module – This serves as the central processing unit.  It houses main controls for the sensor’s operation and communication.</p>
              <p>C.	Sensor Module – This houses the probes for five sensors: pH, Temperature, Dissolved Oxygen, Conductivity and Chlorophyll-a.</p>
              <br />
              <img src="./images/senspak_image_2.png" style={imageStyle}/>
              <br />
              <p>The SensPak is waterproof and can be installed directly on fishpen, buoy or other floating devices. It uses LoRa (long range) communication protocol implemented in a mesh topology to relay data to the base station server. The receiver is  housed in an area with internet access, thus, setups are site-specific and may vary between sites. The near real-time data acquired by the SensPak can be viewed in the HABHub: Harmful Algal Bloom Observing System website: <a href="http://habhub.philhabs.net" target="_blank">http://habhub.philhabs.net</a></p>
            </div>
          </Card.Body>

          <Card.Header>
          <Card.Title><b>Automated Water Quality Monitoring System (AWQMS)</b></Card.Title>
          </Card.Header>
          <Card.Body>
          <div id="awqms" className="container-fluid">

            <p>This sensor system was developed by Drs. Cesar Villanoy and Charina Amedo-Repollo, and Rachel Francisco
            from the Marine Science Institute, University of the Philippines Diliman. This current version makes use
            of sensors from previous instruments and pumps to bring up seawater and courses this through sensors at
            scheduled intervals.</p>
            <br />
              <img src="./images/awqms_1.jpg" style={imageStyle}/>
            <br />
            <p>The temperature, salinity and chlorophyll sensors are located above water in the
            platform, while the dissolved oxygen sensor is permanently submerged below water. It gathers continuous
            in situ data of common water quality parameters. It minimizes the need to retrieve the system either to
            change batteries or to retrieve the data collected by the sensors through featuring a sustainable power
            source and real-time data transmission. </p>
            <br />
              <img src="./images/awqms_2.jpg" style={imageStyle}/>
            <br />
            <p>The sustainable power source feature utilizes solar energy to charge lithium iron phosphate batteries
            to power the system continuously. Automated data recording and real-time data transmission is achieved
            through the use of a microcontroller. The microcontroller commands the sensors to measure every hour.
            Data recorded by the sensors are transmitted to a database through either SMS or internet connection.
            The database is then accessible remotely and data can be monitored using a smartphone.
            This is currently continuously running at Guiguiwanen Channel, Bolinao, with the data publicly
            accessible and also assimilated into a <NavLink to="/models-and-forecasts">HAB forecast model</NavLink>.
            A portable version (PAWQMS) is currently being developed and will be deployed at another site in Bolinao.</p>
            <br />
              <img src="./images/awqms_schematic.jpg" style={imageStyle}/>
            <br />

            </div>
          </Card.Body>
	  		</Card>
	  	</Page.Content>
	  </SiteWrapper>
  )
}

export default SensPak;