import React from "react";

import { 
  Page,
  Card,
  Grid,
} from 'tabler-react';

import SiteWrapper from '../../SiteWrapper';

function SeahabsViewer() {
  return(
	  <SiteWrapper>
	  	<Page.Content>
          <p>
          To view, use the following: <br/>
          username: seahabs_users <br/>
          password:  Seahabs_user8
          </p>

          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center',}}>
            <iframe width="1080" height="720" frameborder="0" scrolling="no" allowfullscreen src="https://arcg.is/058e04"></iframe>
          </div>


          <br/>

          <p>
          You can also download SeAHABS data in GeoTIFF file format from our archives. Just click on the links below to check the available data.
          </p>
          <p>
          <a href="https://drive.google.com/drive/u/0/folders/1ufhZAqlAPgSRP86UznNKhUvN15bdERQN" target="_new">SeAHABS Data Archive:</a> <br/>
          <ul>
          <a href="https://drive.google.com/drive/u/0/folders/1dolbWVhh9kQAkymAbA3kcItm5uDftkHE" target="_new"><li>Chl a Anomaly - 2020</li></a>
          </ul>
          </p>
	  		
	  	</Page.Content>
	  </SiteWrapper>
  )
}

export default SeahabsViewer;