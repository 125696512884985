import React from 'react';

import {
	Page,
	Grid,
	Card,
} from 'tabler-react';

import SiteWrapper from '../../SiteWrapper';
import {Link} from "react-router-dom";

function aboutTheProject() {
	return (
		<SiteWrapper>
			<Page.Content>
				<Grid.Row>
					<Grid.Col lg={9}>
						<Card statusColor="green">
							<Card.Header>
								<Card.Title><b>ABOUT THE PROJECT</b></Card.Title>
							</Card.Header>
							<Card.Body>
								<div className="container-fluid">
									<p><strong>HABHub</strong> is a platform to facilitate the provision of more rapid and increased scale of detection of algal blooms, and robust early-warning systems that would allow for more proactive mitigation and enhanced understanding of HABs. This was born from the foundation that reliable and consistent science-based information is imperative in effectively mitigating and managing the risks fisherfolk face due to coastal threats and disasters such as deteriorated water quality threatening mariculture, harmful algal blooms (HABs) and other types of fish kills.</p>

									<p>HABHub is a means to store and retrieve HAB information and robust models of blooms. Hopefully, these can improve our capacity for adaptive response through the dissemination of information acquired from the monitoring, and models for use in evaluating and improving strategies on monitoring and mitigation of the impacts of HABs and changing coastal resources.</p>

									<p>This is supported through the program funded by the Department of Science and Technology Philippine Council for Agriculture, Aquatic and Natural Resources Research and Development (DOST-PCAARRD) entitled <i>Hazard detection and mitigation tools for algal blooms in a changing marine environment</i> implemented by the Marine Science Institute of the University of the Philippines (UPMSI) in partnership with the Bureau of Fisheries and Aquatic Resources (BFAR). This program hopes to help address the risks from harmful algal blooms and pollution by providing ways to more rapidly detect the conditions and organisms leading to HABs and eventually develop an early-warning system that can be used by shellfish and fish farmers and government agencies.</p>

									<p>The many complex components of HABHub have been made possible by a team of researchers from the Marine Science Institute and the National Institute of Physics of the University of the Philippines:</p>

									<div>
										<strong>SensPak: low-cost sensor</strong>

										<p>The development of a low-cost water quality sensor that transmits near real-time data was led by Dr. Giovanni Tapang of the National Institute of Physics, University of the Philippines Diliman together with Alimmudiun Arriesgado, Justin Custado, Jennifer Maister and Drs. Gil Jacinto and Aletta Yñiguez.</p>

										<strong>SeAHABS</strong>

										<p>The SeAHABS, a software that can be used to detect potential blooms using ocean color on a national scale, was developed by Dr. Laura T. David and Aldwin Almo of the Marine Science Institute, University of the Philippines Diliman.</p>

										<strong>Site-specific Forecasting Model(s)</strong>

										<p>The machine learning model(s) to forecast HAB blooms were developed by Dr. Aletta T. Yñiguez, Zheina Ottong and Andalus Punongbayan of the Marine Science Institute, University of the Philippines Diliman.</p>

										<strong>HABHub</strong>

										<p>The overall HABHub site and informatics system with a relational database, consolidation and deployment of the various components were developed through the efforts of Drs. Aletta T. Yñiguez, Ma. Josefa Pante, Cesar L. Villanoy, Rodrigo Narod Eco, Paul Gravoso, Andalus Punongbayan and Rica Dungog.</p>
									</div>

								</div>
							</Card.Body>
						</Card>
					</Grid.Col>
					<Grid.Col lg={3}>
						<div className="sticky-top" id="abouthab-cards">
							<Card title="About:">
								<Card.Body>
									<table className="ml-5 col text-md-left">
										<tr><Link className="nodecorate" to="/about-the-project" >The Project</Link></tr>
										<tr><Link className="nodecorate" to="/project-partners" >Project Partners</Link></tr>
										<tr><Link className="nodecorate" to ="/contact-us" >Contact Us</Link></tr>
									</table>
								</Card.Body>
							</Card>
						</div>
					</Grid.Col>
				</Grid.Row>
			</Page.Content>
		</SiteWrapper>
	);
}

export default aboutTheProject;
