import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, Redirect} from 'react-router-dom';
import PropTypes from "prop-types";
import { loginUser } from "../redux/actions/auth";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import toast, { Toaster } from 'react-hot-toast';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="/">
        HABHub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/*START OF LOGIN CODE*/
const Login = (props) => {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  /*Application state constants*/
  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();

  const login = async (event) => {
    event.preventDefault();
    setLoading(true);

    const { email, password } = state;

    await dispatch(loginUser(email, password))
    .then(() => {
      setLoading(false);
      props.history.push("/");
      window.location.reload();
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);
    });
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <>
    <Toaster
        position="top-center"
        reverseOrder={true}
      />
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <PersonOutlineOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
        <form className={classes.form} onSubmit={login}>
          <TextField
            id="email"
            name="email"
            value={state.email}
            onChange={handleChange}
            label="Email Address"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            label="Password"
            id="password"
            name="password"
            value={state.password}
            onChange={handleChange}
            label="Password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type={values.showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
          >
            {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>

        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </>
  );
};

export default Login;
