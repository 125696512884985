import React from "react";

import { 
  Page,
  Card,
  Grid,
} from 'tabler-react';

import { Image } from 'react-bootstrap';
import SiteWrapper from '../../SiteWrapper';

const imageStyle = {
  display: 'block',
    margin: '0 auto',
};

function ModelsAndForecasts() {
  return(
	  <SiteWrapper>
	  	<Page.Content>
	  		<Card statusColor="green">
	  			<Card.Header>
            <Card.Title><b>MODELS AND FORECASTS</b></Card.Title>
          </Card.Header>
          <Card.Body>
            <div id="models_and_forecasts" className="container-fluid">
              <h4>Overview</h4>
              <p>Models attempt to capture key aspects of a system in such a way as to provide meaningful and useful results that can provide insights into the system and/or allow us to decide on responses to take. Understanding, much more forecasting these harmful blooms is not a trivial task owing to the large number of species responsible with a range of biological characteristics embedded within the complex attributes and dynamics of coastal waters. Blooms and any ensuing toxicities are a function of the interplay between physico-chemical factors, the life histories of the responsible organisms, and their interactions with other organisms. These challenges thus necessitate multi-pronged, spatially and temporally extensive approaches in order to more fully determine the relevant conditions and mechanisms contributing to bloom development and consequent toxicities. Recent advances in marine observational technologies and modeling tools provide at least the initial means to approach the development of forecasting models.</p>

              <p>This page presents our attempts to come up with forecasting models for HABs for target sites. This would help our coastal communities especially those dependent on shellfish or fish farming to have more options on what to do with their <i>tahong</i> (mussels) or <i>talaba</i> (oysters) or <i>bangus</i> (milkfish) if they had an inkling that their shellfish might become toxic and unharvestable, or if a fish kill could happen sometime soon. In order to forecast, real-time data from the site needs to be assimilated into the model which provides risks or probabilities of a HAB event occurring. As with any model prediction, these have uncertainties and limitations that need to be carefully considered.</p>

              <h4>Bolinao and Anda, Pangasinan</h4>
              <p>The coastal waters of Bolinao and Anda are sites for the mariculture of the milkfish <i>Chanos chanos</i>. The presence of narrow channels in some areas combined with extensive fish cages and fish pens contribute to conditions ideal for harmful algal blooms (HABs) and fish kills.</p>

              <h4>Data</h4>
              <p>The site has been regularly monitored by the Physical Oceanography and Marine Biogeochemistry Oceanography Laboratories of the Marine Science Institute (MSI). Water quality data from 2015-2017, specifically, dissolved oxygen (DO), temperature, salinity, chlorophyll-a concentration, and pH (all taken from 1 meter depth in several sampling stations), were used to train a predictive model for (1) HAB occurrence and (2) fish kill event. These parameters were chosen since these could be easily collected by sensors (see section on <i>SensPak</i>). Here, HAB occurrence is defined as the time when the Bureau of Fisheries and Aquatic Resources (BFAR) issued a shellfish ban in the site. This is based on the detection of paralytic shellfish toxin in shellfish samples exceeding the regulatory limit of 60mg STX equivalent per 100g shellfish meat (<a href="https://www.bfar.da.gov.ph/redtide" target="_blank">https://www.bfar.da.gov.ph/redtide</a>). Fish kill occurrence data were taken from BFAR reports, news aritcles, and MSI monitoring.</p>
            
              <h4>Predictive model - Random Forest Classifier</h4>
              <p>The <a href="https://deepai.org/machine-learning-glossary-and-terms/random-forest" target="_blank">Random Forest (RF) Classifier</a>, a type of machine learning algorithm, was chosen to predict HAB and fish kill occurrences.The RF classifier is an ensemble of decision trees with different tree structures and thresholds for parameters. Each tree decides whether the state of a site is shellfish ban or normal given the time-specific values of water quality parameters. The decisions of all trees are then summarized to get the probability of a shellfish ban or a fish kill event. The trained RF classifier (or the model that best fits the historical data) for HAB occurrence had the following key predictive variables: dissolved oxygen, temperature, and cholorophyll concentration. The model has an accuracy of 98%, meaning that the presence/absence of shellfish ban in the site was predicted correctly in 98% of the cases. Meanwhile, the trained RF classifier for fish kill occurrence had dissolved oxygen, temperature, and salinity as the key predictive variables, with an accuracy of 96%. The details can be found in the publication by <a href="https://doi.org/10.1016/j.scitotenv.2019.136173" target="_blank">Yñiguez and Ottong (2020)</a>. <strong style={{color: "orangered"}}>Note that predictions are contingent on input data from sensors which have their own caveats.</strong></p>

              <h4>Pipeline</h4>
              <p>The shellfish ban and fish kill risks for Bolinao and Anda will be updated daily on the website. First, SensPak of sensor data for a site, namely, chlorophyll concentration, salinity, dissolved oxygen, pH, and sea surface temperature, are pulled from the database (Fig. 1). The data will serve as input to the trained RF classifiers. The model output, the probability of a shellfish ban or fish kill event, is then translated to risk. <strong style={{color: "orangered"}}>Risk has 3 levels: low (0-33% probability), moderate (34-66%), high (67-100%)</strong>. The risk level will be saved on the database and displayed on the HABhub main map whenever a site with a SensPak deployed is selected.</p>
              
              <img src="./images/pipeline.jpg" style={imageStyle}/>
              <p style={{textAlign: 'center'}}><small>Figure 1. Pipeline for updating shellfish ban risk in a site. The same pipeline will apply to updating fish kill risk.</small></p>
            </div>
          </Card.Body>
	  		</Card>
	  	</Page.Content>
	  </SiteWrapper>
  )
}

export default ModelsAndForecasts;