import React, { useState, useEffect } from 'react';
import axiosInstance from '../../constants/axiosApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { 
	Card,
  Grid,
  Table,
  Button,
  Icon,
  Badge,
  Form,
} from 'tabler-react';


function SubmitMonDataAuth(){

  const [state, setState] = useState({
    data_policy: "CC-BY",
    citation: "",
    description: "",
  });

  const [datasetType, setDatasetType] = useState("");
  const [file, setFile] = useState("");
  const [DatasetDropdownOptions, setDatasetDropdownOptions] = useState({});

  const toastId = React.useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleDatasetChange = (event) => { 
    setDatasetType(event.target.value);
  };

  const handleFileChange = event => {
    if (event.target.files.length) {
      setFile(event.target.files[0]);
    }
  };

  const clearFields = () => {
    /*Resets all input fields*/
    Array.from(document.querySelectorAll("input")).forEach(
    input => (input.value = "")
    );
    /*Resets "description" textarea since 
      it can't be reset using setState only!*/
    Array.from(document.querySelectorAll("textarea")).forEach(
    textarea => (textarea.value = "")
    );
    setState({
      data_policy: "CC-BY",
      citation: "",
      description: "",
   });
    setFile("");
  }

  useEffect(() => {
    const fetchDatasetDropdownOptions = async () => {   /*fetch datasets-classification API*/
      const results = await axiosInstance(
        'api/datasets-classification/',
      );
      setDatasetDropdownOptions(results.data);    /*store all dataset classificaton to object 'setDatasetDropdownOptions' for dynamic dropdown*/
      setDatasetType(results.data[0].topic);
      console.log(results.data[0].topic);
      console.log(datasetType);
    };
    fetchDatasetDropdownOptions();
  }, []);

  const Submit = async (event) => {
    event.preventDefault();

    const userInfo = await axiosInstance.get("/user/info/"); /*USE REACT STORE TO BETTER IMPLEMENT THIS!*/
    const formData = new FormData();
    formData.append('user', userInfo.data.email);
    formData.append('dataset_classification', datasetType);
    formData.append('data_policy', state.data_policy);
    formData.append('citation', state.citation);
    formData.append('description', state.description);
    formData.append('file', file);

    /*toast.info("Verifying file...",);*/
    toastId.current = toast("Checking file for errors. Please standby.",{ 
      type: toast.TYPE.INFO,
      autoClose: false
    });
    const response = await axiosInstance.put('/data-contribution/data-upload/', formData,
      {
        timeout: 0
      })
    .then(response => {
      if (response.status === 201) {
        /*File successfully added to database*/
        toast.update(toastId.current, { 
          render: () => <div><Icon prefix="fe" name="check-circle" /> File successfully added to the database.</div>,
          type: toast.TYPE.SUCCESS,
          autoClose: 3000
        });
      }
      else if(response.status === 200){
        /*Error found on file*/
        toast.update(toastId.current, { 
          render: () => <div><Icon prefix="fe" name="x-circle" /> Error Found: <b>{response.data.error}</b></div>,
          type: toast.TYPE.ERROR,
          autoClose: 10000
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.update(toastId.current, { 
        render: () => <div><Icon prefix="fe" name="x-circle" /> File upload error.</div>,
        type: toast.TYPE.ERROR,
        autoClose: 10000
      });
    })
    clearFields();
  };

	return(
    <>
      <Form onSubmit={Submit}>    
    		<Card.Body>
    			<p>Upload datasets as <b>.csv</b> files following the prescribed format<a href="https://docs.google.com/spreadsheets/d/10U5kJ3ZKGDCV1oPPJookvCFIqcWG_BCHC2YgwdbHuFU/edit#gid=1543974942" target="_blank" rel="noopener noreferrer">(?)</a>.</p>  
          <Grid.Row>
            <Grid.Col width={3}>
              <Form.Group label="Dataset type">
                <Form.Select
                  id="dataset_classification"
                  name="dataset_classification"
                  value={datasetType}
                  onChange={handleDatasetChange}
                  required={true}
                >
                  {Object.values(DatasetDropdownOptions).map((DatasetDropdownOptions, i) => {
                    return(
                      <React.Fragment key={i}>
                        <option value={DatasetDropdownOptions.topic}>{DatasetDropdownOptions.topic}</option>
                      </React.Fragment>
                    )
                  })}

                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={2}>
              <Form.Group label="Data access / License">
                <Form.Select
                  id="data_policy"
                  name="data_policy"
                  value={state.data_policy}
                  onChange={handleChange}
                  required={true}
                >
                  <option value="CC-BY">Open access/CC-BY</option>
                  <option value="RESTRICTED">Restricted</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={4}>
              <Form.Group label="Citation (APA)">
                <Form.Input
                  id="citation"
                  name="citation"
                  value={state.citation}
                  onChange={handleChange}
                  placeholder="Author. MI. (Year). Title. Location of publisher. URL."
                  type="text"
                  required={true}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={3}>
              <Form.Group label="Upload file">
               <input
                  type="file"
                  id="file"
                  name="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={12}>
              <Form.Group label="Description">
                <Form.Textarea
                  id="description"
                  name="description"
                  value={state.description}
                  onChange={handleChange}
                  placeholder="About the file.."
                  rows={6}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        <Card.Footer>
          <div className="col text-center">
            <Button pill color="info" icon="upload">Upload File</Button>
          </div>
        </Card.Footer>
      </Form>
    </>
	);
}

export default SubmitMonDataAuth;