import React from "react";

const CustomFooter = () => {

	return(
		<React.Fragment>
			<div className="footer">
				<div className="container">
					<div className="row row">
						<div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
							<ul className="list-unstyled list-inline social text-center">
								<li className="list-inline-item">
									<p>HABHub was developed by Marine Science Institute, University of the Philippines Diliman (UP-MSI); through the generous support of Department of Science and Technology - Philippine Council for Agriculture, Aquatic and Natural Resources Research and Development 
									(DOST-PCAARRD).</p>
								</li>
								<li className="list-inline-item">
									<a href="https://www.dost.gov.ph/" target="_blank" rel="noopener noreferrer">
										<img src="/images/footer_logo/DOST.jpg" alt="DOST" width="75" height="75"/>
									</a>
								</li>
								<li className="list-inline-item">
									<a href="http://www.pcaarrd.dost.gov.ph/home/portal/" target="_blank" rel="noopener noreferrer">
										<img src="/images/footer_logo/PCAARRD.png" alt="PCAARRD" width="75" height="75" />
									</a>
								</li>
								<li className="list-inline-item">
									<a href="https://www.bfar.da.gov.ph/" target="_blank" rel="noopener noreferrer">
										<img src="/images/footer_logo/BFAR.jpg" alt="BFAR" width="85" height="75" />
									</a>
								</li>
								<li className="list-inline-item">
									<a href="https://upd.edu.ph/" target="_blank" rel="noopener noreferrer">
										<img src="/images/footer_logo/UP.png" alt="UP" width="75" height="75" />
									</a>
								</li>
								<li className="list-inline-item">
									<a href="http://www.msi.upd.edu.ph/" target="_blank" rel="noopener noreferrer">
										<img src="/images/footer_logo/MSI.jpg" alt="MSI" width="75" height="75" />
									</a>
								</li>
							</ul>
						</div>	
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default CustomFooter;