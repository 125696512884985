import React, { useState, useEffect } from "react";
import axios from 'axios';
import { API_URL } from '../constants/index.js';
import {Link, Redirect} from 'react-router-dom';
import qs from 'qs';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        HABHub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ResetPassword(props) {

  const [state, setState] = useState({
    email: props.email,
    password: "",
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };
  /*Show hide password for password1 textbox*/
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();

  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const { password } = state;
    /*
     * Basic Axios PUT request was used since content-type is
     * 'application/x-www-form-urlencoded' and would not conform
     * with the set AxiosInstance w/c uses 'application/json'
     */
    const response  = await axios({
      method: 'put',
      url: API_URL+'/user/change_password/'+props.email+'/',
      data: qs.stringify({
        password: password,
      }),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    })
    .then(response => {
      setLoading(false);
      setIsSuccess(true);
    })
    .catch(function (error) {
      setLoading(false);
      console.log(error);
    })
  };

  if (isSuccess) {
    return <Redirect to="/login" />;
  }

  return(
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <VpnKeyOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <br />
        <Typography variant="h6" gutterBottom>
          {props.email}
        </Typography>
        <form className={classes.form} onSubmit={submit}>
          <TextField
            id="password"
            name="password"
            value={state.password}
            onChange={handleChange}
            label="New Password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoComplete="password"
            autoFocus
            type={values.showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
          >
            Change Password
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/login" variant="body2">
                Remember your password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default ResetPassword;
