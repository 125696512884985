import React, { useState, useRef, useEffect } from 'react';
import axiosInstance from '../../../../constants/axiosApi';
import CsvDownloader from 'react-csv-downloader';

import {
    Form,
    Card,
    Button,
    Grid,
  } from 'tabler-react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { Divider, Hidden, Typography } from '@material-ui/core';
import { Grid as MUIGrid } from '@material-ui/core';
import {CircularProgress} from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import { isAuthenticated } from '../../../../constants/authApi';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
		fullWidth: true,
		display: 'flex',
    wrap: 'nowrap'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selected, theme) {
  return {
    fontWeight:
      selected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AvailableDataDownload =() =>{
	const classes = useStyles();
  const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState([]);
	const [export_data, setExportData] = useState([]);
  const [embayment, setEmbayment] = useState([]);
	const [selected_embayment, setSelectedEmbayment] = useState("All Sites");
  const [station, setStation] = useState("");
  const [selected_station, setSelectedStation] = useState("All Stations");
	const [isSelectionUpdated, setIsSelectionUpdated] = useState(false)

	useEffect(() => {
		resetValues();
		axiosInstance.get('api/embayment/')
		.then(res => {
			setEmbayment(res.data);
		})
		.catch(err => {
			console.log(err);
		})

	}, []);

	const resetValues = () => {
		setSelected([]);
		setExportData([]);
		setEmbayment([]);
		setSelectedEmbayment("All Sites");
		setStation([]);
		setSelectedStation("All Stations");
	}

	const handleResetExportData = () => {
		setExportData([]);
	}

	const handleEmbaymentChange = async(e) => {
		setSelectedEmbayment(e.target.value);

		if(e.target.value !== "All Sites"){
			await axiosInstance.get('api/station/')
			.then(res => {
				let filter = res.data.filter(s => {return s.embayment === e.target.value});
				setStation(filter);
			})
			.catch(err => {
				console.log(err);
			})
		} else {
			setSelectedStation("All Stations");
			setStation("");
		}
  };

	const handleStationChange = (e) => {
		setSelectedStation(e.target.value);
	}

	console.log("")

	const handleSubmit = async() => {
		setIsSelectionUpdated(false)
		setExportData([]);
		setLoading(true);
		await axiosInstance.get(`api/download/?fields=station_name,embayment,latitude,longitude,sample_date,time,${selected}`,
			{
				params: {
					embayment: selected_embayment,
					station_name: selected_station,
				}
			})
		.then(res => {
			setExportData(res.data);
			setLoading(false);
		})
		.catch(err => {
			console.log(err);
		})
	}

    return(
      <React.Fragment>
				<div style={{display: "flex", height: 790}}>
        <Card>
	      {/* <Card.Header>
	        <Card.Title>Available Data</Card.Title>
	      </Card.Header> */}
	      <Card.Body>
          <Grid.Row>
            <Grid.Col width={6}>
              <Form.Group label="Site">
                <Form.Select
                  id="embayment"
                  name="embayment"
                  onChange={handleEmbaymentChange}
                >
                  <option value="All Sites">All Sites</option>
                  {Object.values(embayment).map((embayment, i) => {
                    return(
                      <React.Fragment key={i}>
                        <option value={embayment.embayment_name}>{embayment.embayment_name}</option>
                      </React.Fragment>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label="Station">
                <Form.Select
                  id="station"
                  name="station"
                  onChange={handleStationChange}
                >
                  {selected_embayment === "All Sites" || station.length > 0 ?
				  	<option value="All Stations">All Stations</option> : <option value="">None</option>}
                  {Object.values(station).map((station, i) => {
                    return(
                      <React.Fragment key={i}>
                        <option value={station.station_name}>{station.station_name}</option>
                      </React.Fragment>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Form.Group label="Select Variable Types">
						<FormControl className={classes.formControl}>
							<Select
								labelId="demo-mutiple-chip-label"
								id="demo-mutiple-chip"
								multiple
								value={selected}
								onChange={e => {
									setSelected(e.target.value)
									setIsSelectionUpdated(true)
								}}
								input={<Input id="select-multiple-chip" />}
								renderValue={(selected) => (
									<div className={classes.chips}>
										{selected.map((value) => (
											<Chip key={value} label={value} className={classes.chip} color="primary"/>
										))}
									</div>
								)}
								MenuProps={MenuProps}
							>
								<MenuItem disabled>
									<Typography color="primary" variant="button">
										Biological Data
									</Typography>
								</MenuItem>
								<Divider variant="middle" />
								<MenuItem value="cdom">Colored Dissolved Organic Matter (CDOM)</MenuItem>
								<MenuItem value="chl_a">Chlorophyll-A</MenuItem>
								<MenuItem value="doc">Dissolved Organic Carbon</MenuItem>
								<MenuItem value="flourescence">Pigment Concentration</MenuItem>

								<MenuItem disabled>
									<Typography color="primary" variant="button">
										Environmental Data
									</Typography>
								</MenuItem>
								<Divider variant="middle" />
								<MenuItem value="nh4">Ammonia Concentration</MenuItem>
								<MenuItem value="do">Dissolved Oxygen</MenuItem>
								<MenuItem value="no3">Nitrate</MenuItem>
								<MenuItem value="nox">Nitrate+Nitrite Concentration</MenuItem>
								<MenuItem value="no2">Nitrite</MenuItem>
								<MenuItem value="sio4">Orthosilicate</MenuItem>
								<MenuItem value="ph">pH</MenuItem>
								<MenuItem value="po4">Phosphate</MenuItem>
								<MenuItem value="conductivity">Salinity</MenuItem>
								<MenuItem value="pressure">Seawater Pressure</MenuItem>
								<MenuItem value="sio2">Silica</MenuItem>
								<MenuItem value="temperature">Temperature</MenuItem>
								<MenuItem value="tin">Total Inorganic Nitrogen</MenuItem>
								<MenuItem value="p">Total Phosphorous Concentration</MenuItem>
								<MenuItem value="tss">Total Suspended Solids</MenuItem>
								<MenuItem value="turbidity">Turbidity</MenuItem>
								<MenuItem value="density">Water Density</MenuItem>

								<MenuItem disabled>
									<Typography color="primary" variant="button">
										Shellfish Data
									</Typography>
								</MenuItem>
								<Divider variant="middle" />
								<MenuItem value="toxicity">Toxicity</MenuItem>
								<MenuItem value="toxin">Toxin</MenuItem>
							</Select>
						</FormControl>
          </Form.Group>
	      </Card.Body>
	      <Card.Footer>
					{/* <MUIGrid container direction="row" justifyContent="center" alignItems="center" className="col text-center">
						<MUIGrid item xs={6}>
              <Button pill outline color="primary" type="button" onClick={handleSubmit} disabled={selected.length === 0 ? true : false}>Fetch Data</Button>
						</MUIGrid>
						<MUIGrid item xs={6}>
						<CsvDownloader datas={export_data} filename="habdata">
              <Button pill color="primary" type="button" icon={ !loading && "download" } onClick={handleResetExportData} disabled={export_data.length === 0 ? true : false}>{loading ?  <a><CircularProgress size={12}/>{' '}Loading</a> : "Download"}</Button>
            </CsvDownloader>
						</MUIGrid>
					</MUIGrid> */}

					<MUIGrid container direction="row" justifyContent="center" alignItems="center" className="col text-center">
					{
						(export_data.length > 0 && !isSelectionUpdated) ?
						<MUIGrid item xs={12}>
							<CsvDownloader datas={export_data} filename="habdata">
								<Button pill color="primary" type="button" icon={ !loading && "download" } onClick={handleResetExportData}>Download</Button>
							</CsvDownloader>
						</MUIGrid>
						:
						<MUIGrid item xs={12}>
              <Button pill color="primary" type="button" onClick={handleSubmit} disabled={selected.length === 0 ? true : false}>{loading ? <a><CircularProgress size={12}/>{' '}Loading</a>: "Submit"}</Button>
						</MUIGrid>
					}
					</MUIGrid>

	      </Card.Footer>
	    </Card>
			</div>
    	</React.Fragment>
  	);
}

export default AvailableDataDownload