import React, { Component } from "react";
import axiosInstance from '../constants/axiosApi';
import { useDispatch, useSelector } from "react-redux";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Link} from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        HABHub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignUp extends Component {
  constructor(props){
    super(props);
    this.state = {
      email:"",
      password: "",
      first_name: "",
      last_name: "",
      institution: "",
      position: "",
      showPassword: false,
      errors:{}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleClickShowPassword(event) {
    this.setState({showPassword: !this.state.showPassword});
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  async handleSubmit(event) {
    event.preventDefault();
    try {
        const response = await axiosInstance.post('/user/create/', {
          email: this.state.email,
          password: this.state.password,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          institution: this.state.institution,
          position: this.state.position
        });
        // window.location.href = "/email-validation";
        window.location.href = "/#/static/email-validation";
        // Disabled email validation in lieu of manual account validation as requested by ATY
        // window.location.href = "/#/static/manual-signup-validation";
        return response;
    } catch (error) {
      console.log(error.stack);
      this.setState({
        errors:error.response.data
      });
    }
  }

  render(){
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PersonAddOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="first_name"
                  name="first_name"
                  value={this.state.first_name}
                  onChange={this.handleChange}
                  label="First Name"
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="fname"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="last_name"
                  name="last_name"
                  value={this.state.last_name}
                  onChange={this.handleChange}
                  label="Last Name"
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="institution"
                  name="institution"
                  value={this.state.institution}
                  onChange={this.handleChange}
                  label="Institution"
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="institution"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="position"
                  name="position"
                  value={this.state.position}
                  onChange={this.handleChange}
                  label="Position"
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="position"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  label="Email Address"
                  type="email"
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  label="Password"
                  helperText="Length must be greater than 8 characters"
                  variant="outlined"
                  required
                  fullWidth
                  type={this.state.showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}
export default withStyles(styles)(SignUp);
