import React, { Component } from 'react';
import Leaflet from 'leaflet';
import { withRouter, Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image'
import {
  Page,
  Grid,
  Card,
  BlogCard,
  Alert,
  Form,
  Button,
} from 'tabler-react';

import { Jumbotron, Container } from 'react-bootstrap';

import CustomFooter from "../../pages/CustomFooter";
import MapAdvisory from "./MapAdvisory";
import SiteWrapper from '../../SiteWrapper';

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/'

const bannerStyle = {
  color: 'white',
  textAlign: 'center',
  backgroundImage: "url(" + "./images/banner.png" + ")",
};

class HabPortal extends Component {

  gotoAboutTheWebsite = () => {
    this.props.history.push('/about-the-project');
  }

  gotoHabs = () => {
    this.props.history.push('/harmful-algal-blooms');
  }

  gotoCoastsAndHabs = () => {
    this.props.history.push('/coasts-and-habs');
  }

  gotoHabDataPortal = () => {
    this.props.history.push('/data-portal');
  }
  gotoSeAHABS = () => {
    this.props.history.push('/seahabs-viewer');
  }
  gotoModelsAndForecasting = () => {
    this.props.history.push('/models-and-forecasts');
  }

  render(){
    return (
      <SiteWrapper>
        <Page.Content>
          <Jumbotron style={bannerStyle} fluid>
            <Container>
              <h1 style={{textShadow: "5px 5px 10px black"}}><strong>Harmful Algal Bloom (HAB)</strong></h1>
              <h1 style={{textShadow: "5px 5px 10px black"}}><strong>Observing System</strong></h1>
              <h4 style={{textShadow: "5px 5px 10px black"}}>
                Information, tools and networks to secure coastal waters and communities
              </h4>
              <Button pill color="info" onClick={this.gotoAboutTheWebsite}>Learn more</Button>
            </Container>
          </Jumbotron>
          <Grid.Row>
              <Grid.Col lg={6}>
                <Grid.Row>
                  <Grid.Col lg={12}>
                    <Card statusColor="green">
                      <Link className="nodecorate" to="/harmful-algal-blooms">
                        <Card.Header>
                            <Card.Title><b>HARMFUL ALGAL BLOOMS</b></Card.Title>
                        </Card.Header>
                      </Link>
                      <Card.Body>
                        <Grid.Row>
                          <Grid.Col width={6}>
                            <p style={{textAlign: "justify"}}>
                              Harmful Algal Bloom (HAB), commonly known as red tide, is a major environmental problem that occurs when the excessive growth of phytoplankton result to toxin production or cause harmful effects to marine organisms, humans and its environment.
                            </p>
                            <Button pill color="info" onClick={this.gotoHabs}>Read more</Button>
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <Image src="/images/hab.png" fluid />
                          </Grid.Col>
                        </Grid.Row>
                      </Card.Body>
                    </Card>
                  </Grid.Col>
                  <Grid.Col lg={12}>
                    <Card statusColor="green">
                      <Link className="nodecorate" to="/coasts-and-habs">
                        <Card.Header>
                            <Card.Title><b>COASTS AND HABS</b></Card.Title>
                        </Card.Header>
                      </Link>
                      <Card.Body>
                        <Grid.Row>
                          <Grid.Col width={6}>
                            <Image src="/images/coasts-and-habs.jpg" fluid />
                          </Grid.Col>
                          <Grid.Col width={6}>
                            <p style={{textAlign: "justify"}}>
                              The Community Alliance to Save our Threatened Seas (COASTS) aims to build a network of stakeholders from coastal commumnities, academe and government agencies concerned with mitigating the effects of Harmful Algal Blooms (HABs).
                            </p>
                            <Button pill color="info" onClick={this.gotoCoastsAndHabs}>Read more</Button>
                          </Grid.Col>
                        </Grid.Row>
                      </Card.Body>
                    </Card>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
              <Grid.Col lg={6}>
                <Grid.Row className="align-items-right">
                  <Alert type="info" icon="radio">
                    <Link className="nodecorate" to="/hab-advisory">
                      <strong>HAB Advisory</strong>
                    </Link>
                  </Alert>
                  <Alert type="warning" icon="alert-triangle">
                    <strong>HAB Risk Probability.</strong> Occurence on some sites.
                  </Alert>
                </Grid.Row>
                <Grid.Row>

                  <MapAdvisory>
                  </MapAdvisory>

                </Grid.Row>
              </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col sm={6} lg={4}>
              <Card>
                <Image src="/images/hab_data_portal.png" width="374" height="234" />
                <div className="explore_overlay">
                  <ul className="list-unstyled list-inline text-center">
                    <li className="list-inline-item" style={{display: "block"}}>
                      <h3>HAB DATA PORTAL</h3>
                    </li>
                    <li className="list-inline-item" style={{display: "block"}}>
                      <Button pill color="info" onClick={this.gotoHabDataPortal}>Explore</Button>
                    </li>
                  </ul>
                </div>
              </Card>
            </Grid.Col>
            <Grid.Col sm={6} lg={4}>
              <Card>
                <Image src="/images/seahabs.png" width="374" height="234" />
                <div className="explore_overlay">
                  <ul className="list-unstyled list-inline text-center">
                    <li className="list-inline-item" style={{display: "block"}}>
                      <h3>SeAHABS</h3>
                    </li>
                    <li className="list-inline-item" style={{display: "block"}}>
                      <Button pill color="info" onClick={this.gotoSeAHABS}>Explore</Button>
                    </li>
                  </ul>
                </div>
              </Card>
            </Grid.Col>
            <Grid.Col sm={6} lg={4}>
              <Card>
                <Image src="/images/pipeline.jpg" width="374" height="234" />
                <div className="explore_overlay">
                  <ul className="list-unstyled list-inline text-center">
                    <li className="list-inline-item" style={{display: "block"}}>
                      <h3>MODELS AND FORECASTING</h3>
                    </li>
                    <li className="list-inline-item" style={{display: "block"}}>
                      <Button pill color="info" onClick={this.gotoModelsAndForecasting}>Explore</Button>
                    </li>
                  </ul>
                </div>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
        <CustomFooter />
      </SiteWrapper>
    )
  }
}
export default withRouter(HabPortal);
