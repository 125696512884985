import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../constants/axiosApi';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts)

Highcharts.setOptions({
	global: {
		useUTC: false
	}
})

const Timeseries = (props) => {
  const [data, setData] = useState([]);
  const [nextUrl, setNextUrl] = useState('');
  const [prevUrl, setPrevUrl] = useState('');
  const [label, setLabel] = useState('');
  const [units, setUnits] = useState('');


	const fetchData = useCallback((async (url='') => {
		try {
			const res = await axiosInstance.get(url? url+'':'api/sensor/?ordering=date_time', {
				params: {
					station_name: JSON.stringify(props.id)
				}
			});

			const responseData = res.data.results;

			setNextUrl(res.data.next);
			setPrevUrl(res.data.previous);

			let newLabel = '';
			let newUnits = '';
			let newData = [];

			if (props.timeseries === 'temp') {
				newLabel = 'Temperature';
				newUnits = '°C';
				newData = responseData.map(el => [
					Number(el.unixtime),
					Number(el.temp)
				]);
			} else if (props.timeseries === 'ph') {
				newLabel = 'pH';
				newUnits = 'pH';
				newData = responseData.map(el => [
					Number(el.unixtime),
					Number(el.ph)
				]);
			} else if (props.timeseries === 'salinity') {
				newLabel = 'Salinity';
				newUnits = 'PSU';
				newData = responseData.map(el => [
					Number(el.unixtime),
					Number(el.salinity)
				]);
			} else if (props.timeseries === 'chl_a') {
				newLabel = 'Chlorophyll-A';
				newUnits = 'mg/m3';
				newData = responseData.map(el => [
					Number(el.unixtime),
					Number(el.chl_a)
				]);
			} else if (props.timeseries === 'cdom') {
				newLabel = 'Colored Dissolved Organic Matter';
				newUnits = 'mg/m3';
				newData = responseData.map(el => [
					Number(el.unixtime),
					Number(el.cdom)
				]);
			} else {
				newLabel = 'Dissolved Oxygen';
				newUnits = 'mg/L';
				newData = responseData.map(el => [
					Number(el.unixtime),
					Number(el.do)
				]);
			}

			setLabel(newLabel);
			setUnits(newUnits);
			setData(newData);
		} catch (error) {
			console.error(error);
		}
	}), [props.id, props.timeseries]);

  useEffect(() => {


    fetchData();
  }, [fetchData]);

  const options = {
		title: {
			text: label + ' plot of sensor ' + props.name
		},
		subtitle: {
			text: `Source: ${props.name.toUpperCase().includes("CCMS") === true ? "CCMS" : " Senspak"}`
		},
		xAxis: {
			type: 'datetime',
			startOnTick: true,
			endOnTick: true,
			showLastLabel: true,
      allowDecimals: false,
			labels: {
        formatter: function() {
					return Highcharts.dateFormat(`%b %d, %Y;<br /> %H:%M`,(this.value*1000));
        }
      },
			title: {
				text: 'Datetime',
				scalable: false
			}
		},
		yAxis: {
			title: {
				text: label + ' (' + units + ')'
			}
		},
		series: [{
			name: label,
			data,
			tooltip: {
				valueDecimals: 2,
			},
		}],
		chart: {
		 type: 'scatter',
		 zoomType: 'xy'
		},
		tooltip: {
			//headerFormat:'{xAxis}<br>',
			//pointFormat: '{series.name}: <b>{point.y}</b> ' + this.state.units
			formatter: function () {
				return 'x: '+Highcharts.dateFormat('%a, %b %d, %Y; %I:%M %p',(this.x*1000))+'<br>y: <b>'+this.y+'</b>';
			}
		},
		credits:{
			href: "http://habhub.philhabs.net/",
			text: "habhub.philhabs.net"
		},
		exporting: {
			buttons: [
				{
					text: 'Next>',
					onclick: function () {
						handlePrevClick();
					},
					disabled: Boolean(prevUrl),
				},
				{
					text: '<Prev',
					onclick: function () {
						handleNextClick();
					},
					disabled: Boolean(nextUrl),
				},

			]
		}
	};

  const handleNextClick = () => {
		// Data is sorted from most recent to oldest, so nextUrl loads next batch (60) of older data
		fetchData(nextUrl);
  };

  const handlePrevClick = () => {
		// Data is sorted from most recent to oldest, so prevUrl loads next batch (60) of newest data
		fetchData(prevUrl);
  };

  return (
      <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={options} />
  );
};

export default Timeseries;
