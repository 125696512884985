/*
 *  NOTE: When deploying from remote server, always set url to that
 *  of remote url so axios will get values from remote and not from localhost.
 *  Change value for localhost or development server
 *
 */


/*
 * BiOME IP
 */
// export const API_URL = "https://10.199.20.25:8000";

/*
 * Linode - Development
 */
// export const API_URL = "http://139.162.36.156:8000";

/*
 * Linode - Development - pre-prod
 */
// export const API_URL = "http://139.162.36.156";

/*
 * ASTI IP - PRODUCTION
 */
export const API_URL = "https://habhub.philhabs.net";


/*
 * localhost dev IP
 */
//  export const API_URL = 'http://127.0.0.1:8000';

 /*
	* Redux configureStore to use (Dev/Prod)
	*/
 export const NODE_ENV = 'development';