import React from "react";
import {
  Card,
  StampCard
} from 'tabler-react';

const Disclaimer = () => {

	return(
		<React.Fragment>
			<Card>
          <StampCard header="DISCLAIMER" icon="alert-triangle" color="orange" />
          <p className="text-muted" style={{padding: 10}}>
          	All products published on this site are prototype products and this site is still in development. This website provides a compilation and display service for distributed data products that are derived from various sources. We have attempted to format such data and products in a more standard and potentially useful format, however, we cannot be liable for omissions or inaccuracies in the datasets. We also value the contributions of our partners in this website and make an effort to give them credit for their contributions. We welcome suggestions for clarification or correction, as appropriate.
          </p>
      </Card>
		</React.Fragment>
	);
}

export default Disclaimer;