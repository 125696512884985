import React from "react";
import {
  Page,
  Grid,
  Card,
} from 'tabler-react';

import { Image, Carousel } from 'react-bootstrap';
import Divider from '@material-ui/core/Divider';
import SiteWrapper from "../../SiteWrapper";
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";

function HarmfulAlgalBlooms() {
  return (
  	<SiteWrapper>
  		<Page.Content>
  			<Grid.Row>
	       		<Grid.Col lg={7}>
	       			<Card statusColor="green">
		       			<Card.Body>
			            	<div id="what_habs" className="container-fluid">
			            		<Image src="./images/habs_habinfo.png" fluid />
				            	<br /><br />
				            	<h3>What are Harmful Algal Blooms (HABs)?</h3>
											<p>Harmful Algal Bloom (HAB), commonly known as red tide, occurs when microscopic algae called phytoplankton accumulate and grow out of control. These can result to toxin production or cause harmful effects to marine organisms, humans and its environment. The phytoplankton is the foundation of the food web of most marine life. They are invisible to the naked eye but in large numbers discoloration in the water may occur depending on the algae present.</p>

											<Carousel>
											  <Carousel.Item>
											    <img
											      className="d-block w-100"
											      src="./images/phytoplankton/micrographs1.png"
											      alt="First slide"
											    />
											  </Carousel.Item>
											  <Carousel.Item>
											    <img
											      className="d-block w-100"
											      src="./images/phytoplankton/micrographs2.png"
											      alt="Second slide"
											    />
											  </Carousel.Item>
											  <Carousel.Item>
											    <img
											      className="d-block w-100"
											      src="./images/phytoplankton/p.nitzschia.jpg"
											      alt="Third slide"
											    />
											    <Carousel.Caption>
											      <p>Pseudo nitzschia sp.</p>
											    </Carousel.Caption>
											  </Carousel.Item>
											  <Carousel.Item>
											    <img
											      className="d-block w-100"
											      src="./images/phytoplankton/Scrippsiella_trochoidea_1.jpg"
											      alt="Fourth slide"
											    />
											    <Carousel.Caption>
											      <p>Scrippsiella trochoidea</p>
											    </Carousel.Caption>
											  </Carousel.Item>
											  <Carousel.Item>
											    <img
											      className="d-block w-100"
											      src="./images/phytoplankton/protoperidinium.jpg"
											      alt="Fifth slide"
											    />
											    <Carousel.Caption>
											      <p>Protoperidinium sp.</p>
											    </Carousel.Caption>
											  </Carousel.Item>
											  <Carousel.Item>
											    <img
											      className="d-block w-100"
											      src="./images/phytoplankton/g.toxicus.jpg"
											      alt="Ninth slide"
											    />
											    <Carousel.Caption>
											      <p>Chaetoceros sp. (chained cell walls) and Gambierdiscus toxicus (round cell)</p>
											    </Carousel.Caption>
											  </Carousel.Item>
											  <Carousel.Item>
											    <img
											      className="d-block w-100"
											      src="./images/phytoplankton/Thalassiosira_1.jpg"
											      alt="Sixth slide"
											    />
											    <Carousel.Caption>
											      <p>Thalassiosira sp.</p>
											    </Carousel.Caption>
											  </Carousel.Item>
											  <Carousel.Item>
											    <img
											      className="d-block w-100"
											      src="./images/phytoplankton/Alexandrium_1.jpg"
											      alt="Seventh slide"
											    />
											    <Carousel.Caption>
											      <p>Alexandrium sp.</p>
											    </Carousel.Caption>
											  </Carousel.Item>
											  <Carousel.Item>
											    <img
											      className="d-block w-100"
											      src="./images/phytoplankton/Coscinodiscus_2.jpg"
											      alt="Eighth slide"
											    />
											    <Carousel.Caption>
											      <p>Coscinodiscus sp.</p>
											    </Carousel.Caption>
											  </Carousel.Item>
											</Carousel>

											<br />
											<p>The growth of these phytoplankton is affected by certain conditions like seasons often combined with pollution from extensive aquaculture operations and improper waste management. It is a major environmental and health problem that can result to widespread death of marine organisms due to lack of oxygen in the water column caused by excessive phytoplankton growth and poisoning via human consumption of shellfish containing toxins from certain phytoplankton.</p>
											<p>HABs is a complicated phenomenon which require strong partnerships between governing agencies, communities, industry and academe to better understand where, when, how they happen and what we can do to prevent or lessen their impacts.</p>
											<br /><br />
											<Divider />
											<br /><br />
										</div>

										<div id="where_habs" className="container-fluid">
											<h3>Where do HABs in the Philippines usually occur?</h3>
											<p>The first confirmed harmful algal bloom occurrence in the Philippines was in 1983 in Maqueda Bay, Western Samar.  There are anecdotal reports of potential blooms that occurred in Manila Bay as early as 1908 (Smith, 1908 cited in Maclean, 1984) and data from sediment cores confirm the presence of <i>Pyrodinium</i> cysts at least since the 1920s (Furio et al., 1996). The Maqueda Bay HAB was due to <i>Pyrodinium bahamense</i> which caused 278 paralytic shellfish poisoning (PSP) cases with 21 deaths due to the consumption of the mussel <i>Perna viridis</i> (Estudillo and Gonzales, 1984). In the years after, this dinoflagellate has been and still is the major harmful algal species in the country. In recent years, other toxic species have contributed to poisoning and fish kills have become a recurrent problem. HAB events in the country usually occur in small embayments that are sites for mariculture or wild harvest of shellfish (e.g., Villanoy et al., 2006; Yñiguez et al., 2018). A few occur in channels (e.g., Pangasinan and Bohol) or nearshore in more exposed coastal areas (e.g., Carigara Bay in Leyte).</p>
											<Image src="./images/habs_where.png" fluid />
											<br /><br />
											<br /><br />
											<Divider />
											<br /><br />
										</div>

										<div id="seafood_habs" className="container-fluid">
											<h3>What seafoods are affected by HABs?</h3>
											<p>Shellfishes are mostly affected by the occurrence of these algal blooms. Due to their feeding strategy, they accumulate these toxins making them unsafe for human consumption. However, the density of these organisms during a bloom can also lead to other environmental problems triggering fish kills in some areas.</p>
											<br /><br />
											<Divider />
											<br /><br />
										</div>

										<div id="toxic_habs" className="container-fluid">
											<h3>What happens if you eat toxic shellfish affected by HABs?</h3>
											<p>HAB-causing organisms harbor different kinds of toxins that have different effects on humans. Some toxins may cause nausea, vomiting, headache, rash, and diarrhea. Unfortunately, more potent toxins can cause paralysis and even death.</p>
											<br /><br />
											<Divider />
											<br /><br />
										</div>

										<div id="when_habs" className="container-fluid">
											<h3>When do Harmful Algal Blooms occur?</h3>
											<p>In certain areas in the Philippines, HABs often occur during the months of August-September. However, HAB is a complicated phenomenon caused by many confounding factors which highlights the importance of extensive monitoring and detection.</p>
											<br /><br />
											<Divider />
											<br /><br />
										</div>

										<div id="how_habs" className="container-fluid">
											<h3>How do Harmful Algal Blooms affects us?</h3>
											<Image src="./images/habs_how.png" fluid />
											<br /><br /><br /><br />
											<Divider />
											<br /><br />
										</div>

										<div id="monitor_habs" className="container-fluid">
											<h3>Why monitor our coastal waters?</h3>
											<p>The physical and chemical conditions of our oceans are important characteristics that determine the state of the ocean and the organisms found within it. Depending on these conditions, the properties of seawater can affect water movement and organisms that can survive. Monitoring the physical and chemical conditions of our oceans is now more critical in light of the rapid changes occurring both at the local and global level. Inputs of sewage, sediments and other pollutants can have a large impact on coastal ecosystems. Climate change results to warmer and more acidic waters that can also lead to shifts in marine systems. To understand how our oceans are changing, there is a need to monitor the physical and chemical conditions of various areas at different times. This monitoring is done using various instruments or sensors, which can now be automated and provide information in real-time. These new technologies allow for higher resolution monitoring with less effort and expense.</p>

											<p>Some of the important parameters that need to be measured in marine and freshwater systems are light, temperature, salinity, pH, dissolved oxygen (DO) and chlorophyll-a. Light is a fundamental requirement for photosynthesis and the availability at the surface and at different depths provides information on the available energy for primary production. Temperature and salinity are basic properties of water that would affect the growth of organisms as well as the density of the water. The level of pH gives information on how acidic, neutral or basic the water is and its possible effect on the organisms. For example, lowering pH levels can make the shells of oysters and structure of corals weak and brittle. Dissolved oxygen is a key parameter that gives an indication on the health of the marine or freshwater system. Oxygen is needed by most organisms, and if seas become hypoxic (low dissolved oxygen concentration at less than 2mg/L) this can lead to the death of organisms in the water. Chlorophyll-a is the basic pigment present in primary producers using oxygen for photosynthesis and thus provides a good indication of the concentration of primary producers (for example, phytoplankton and photosynthetic bacteria) in the water. Real-time information on water parameters like this can provide us an idea of the status and health of our coastal waters and help forecast the occurrences of toxic algal blooms and fish kills.</p>
										</div>

								</Card.Body>
	       			</Card>
	       		</Grid.Col>
	       		<Grid.Col lg={5}>
		       		<div className="sticky-top" id="abouthab-cards">
								<Card>
						      <Card.Body>
						      	<table className="ml-5 col text-md-left">
						      		<tbody>
						      		<tr><td><HashLink className="nodecorate" to="#what_habs">What are harmful algal blooms?</HashLink></td></tr>
						      		<tr><td><HashLink className="nodecorate" to="#where_habs">Where do HABs in the Philippines usually occur?</HashLink></td></tr>
						        	<tr><td><HashLink className="nodecorate" to="#seafood_habs">What seafoods are affected by HABs?</HashLink></td></tr>
						        	<tr><td><HashLink className="nodecorate" to="#toxic_habs">What happens if you eat toxic shellfish affected by HABs?</HashLink></td></tr>
						        	<tr><td><HashLink className="nodecorate" to="#when_habs">When do Harmful Algal Blooms occur?</HashLink></td></tr>
						        	<tr><td><HashLink className="nodecorate" to="#how_habs">How do Harmful Algal Blooms affects us?</HashLink></td></tr>
						        	<tr><td><HashLink className="nodecorate" to="#monitor_habs">Why monitor our coastal waters?</HashLink></td></tr>
						        	</tbody>
						        </table>
						      </Card.Body>
						    </Card>
							</div>
	       		</Grid.Col>
       	</Grid.Row>
  		</Page.Content>

  	</SiteWrapper>
  )
}

export default HarmfulAlgalBlooms;
