import React from "react";
import axiosInstance from '../../constants/axiosApi';
import { Link } from 'react-router-dom';

const ProfilePage = () => {
  const handleClick = async () => {
    const response = await axiosInstance.get("user/info/");
    alert(JSON.stringify(response.data));
  };
  return (
    <div>
      <h1>Profile page</h1>
      <p>Only logged in users should see this</p>
      <button onClick={handleClick}>GET protected</button>
      <Link to="/">
          <button type="button">
            Home
          </button>
      </Link>
    </div>
  );
};

export default ProfilePage;