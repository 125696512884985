import axios from 'axios';
import { API_URL } from './index.js';

const axiosInstanceNoAuth = axios.create({
    baseURL: API_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'accept': 'application/json'
    }
});

export default axiosInstanceNoAuth;