import React, {Component} from 'react';
import axiosInstance from '../../../constants/axiosApi';
import {Button, Badge, Icon} from 'tabler-react';
import {
  FeatureGroup,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { isAuthenticated } from '../../../constants/authApi';

import { redMarker, blueMarker, orangeMarker, yellowMarker, greenMarker, cyanMarker } from "../../reusables/mapMarker";
import { popupContent, popupHead, popupText } from "../../reusables/popupStyles";
import Timeseries from "../../reusables/timeseries";

/*import PropTypes from 'prop-types';*/
import Modal from 'react-modal';


export default class MapMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [12.599512, 121.984222],
      zoom: 6,
      advisoryAPI: [],
      monitoringAPI: [],
      habriskAPI: [],
      habHistoryAPI: [],
      siteHistoryAPI: [],
      stationName: '',
      stationID: null,
      showTimeSeries: '',
      showModal: false,
    };

    // Event Handlers
    this.getStationName = this.getStationName.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  getStationName(name, id, timeseries){
    this.setState(
      {
        stationName: name,
        stationID: id,
        showTimeSeries: timeseries,
        showModal: true,
      }
    );
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  async componentDidMount() {
    //Modal Properties
    Modal.setAppElement('body');
    Modal.defaultStyles.overlay.backgroundColor = '#212121';
    Modal.defaultStyles.content.top = '25%';
    Modal.defaultStyles.content.left = '15%';
    Modal.defaultStyles.content.right = '15%';
    Modal.defaultStyles.content.bottom = 'auto';

    await axiosInstance.get('api/sensor-latest-data/')  // API request for Sensor Observations showing latest data on pop-up
      .then(res => {
        const monitoringAPI = res.data;
        this.setState({ monitoringAPI });
      })
      .catch(function (error) {
      console.log(error);
      })

    await axiosInstance.get('api/habrisk/')  // API request for Forecast Model status
      .then(res => {
        const habriskAPI = res.data;
        this.setState({ habriskAPI });
      })
      .catch(function (error) {
      console.log(error);
      })

    await axiosInstance.get('api/station/')             // API request for HAB historical data
      .then(res => {
        const advisoryAPI = res.data;
        this.setState({ advisoryAPI });
      })
      .catch(function (error) {
      console.log(error);
      })
  }

  render() {
    const {monitoringAPI} = this.state;   // Sensor Observations
    const {advisoryAPI} = this.state;     // HAB Historical Data
    const {habriskAPI} = this.state;
    return (
      <div>
        <Modal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          >
          <Timeseries name={this.state.stationName} id={this.state.stationID} timeseries={this.state.showTimeSeries} />
          <div className="col text-center">
            <Button type="button" onClick={this.handleCloseModal} pill color="primary">Close</Button>
          </div>
        </Modal>
        <MapContainer className="leaflet_monitoring_map" center={this.state.center} zoom={this.state.zoom}>
          <LayersControl collapsed={false} position="topright">
            <LayersControl.BaseLayer checked name="ESRI World Imagery">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="ESRI World Street Map">
              <TileLayer
                attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
                url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay checked name="Sensor Observations">
              <LayerGroup>
                <MarkerClusterGroup>
                  {monitoringAPI.map((e, i) => {
                    return (
                      <FeatureGroup name={e.name} key={i}>
                          <Marker
                            position={{lat:e.latitude, lng:e.longitude}}
                            icon={greenMarker}>
                            <Popup>
                              <div style={popupContent}>
                                <div style={popupHead}>
                                  {e.name}
                                </div>
                                <div style={popupText}>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>Location:</td>
                                      <td>{e.longitude}, {e.latitude}</td>
                                    </tr>
                                    <tr>
                                      <td>Station Depth:</td>
                                      <td>{e.station_depth} m</td>
                                    </tr>
                                    <tr>
                                      <td>Last Updated:</td>
                                      <td>{e.date_time}</td> {/* NOTE: USE e.date and e.time ONLY FOR SENSOR TIMESTAMP ONLY */}
                                    </tr>
                                    <tr>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>Temperature (°C):</td>
                                      <td>{e.temp}</td>
                                      <td>
                                        <button type="button" onClick={() => {this.getStationName(e.name, e.station_name, 'temp')}}
                                        title="Display Temperature timeseries" className="btn btn-outline-dark btn-sm"><i className="fa fa-area-chart"></i></button>
                                      </td>
                                    </tr>
                                    {Number(e.ph) !== -1 &&
                                      <tr>
                                        <td>pH:</td>
                                        <td>{e.ph}</td>
                                        <td>
                                          <button type="button" onClick={() => {this.getStationName(e.name, e.station_name, 'ph')}}
                                          title="Display pH timeseries" className="btn btn-outline-dark btn-sm"><i className="fa fa-area-chart"></i></button>
                                        </td>
                                      </tr>
                                    }
                                    {Number(e.do) !== -1 &&
                                      <tr>
                                        <td>Dissolved Oxygen (mg/L):</td>
                                        <td>{e.do}</td>
                                        <td>
                                          <button type="button" onClick={() => {this.getStationName(e.name, e.station_name, 'do')}}
                                          title="Display Dissolved Oxygen timeseries" className="btn btn-outline-dark btn-sm"><i className="fa fa-area-chart"></i></button>
                                        </td>
                                      </tr>
                                    }
                                    {Number(e.chl_a) !== -1 &&
                                      <tr>
                                        <td>Chlorophyll-A (mg/m3):</td>
                                        <td>{e.chl_a}</td>
                                        <td>
                                          <button type="button" onClick={() => {this.getStationName(e.name, e.station_name, 'chl_a')}}
                                          title="Display Chlorophyll-A timeseries" className="btn btn-outline-dark btn-sm"><i className="fa fa-area-chart"></i></button>
                                        </td>
                                      </tr>
                                    }
                                    {Number(e.salinity) !== -1 &&
                                    <tr>
                                      <td>Salinity ({e.salinity_unit}):</td>
                                      <td>{e.salinity}</td>
                                      <td>
                                        <button type="button" onClick={() => {this.getStationName(e.name, e.station_name, 'salinity')}}
                                        title="Display Salinity timeseries" className="btn btn-outline-dark btn-sm"><i className="fa fa-area-chart"></i></button>
                                      </td>
                                    </tr>
                                    }
                                    {Number(e.cdom) !== -1 &&
                                    <tr>
                                      <td>CDOM (mg/m3):</td>
                                      <td>{e.cdom}</td>
                                      <td>
                                        <button type="button" onClick={() => {this.getStationName(e.name, e.station_name, 'cdom')}}
                                        title="Display CDOM timeseries" className="btn btn-outline-dark btn-sm"><i className="fa fa-area-chart"></i></button>
                                      </td>
                                    </tr>
                                    }
                                    <tr>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                                </div>
                              </div>
                            </Popup>
                          </Marker>
                      </FeatureGroup>
                    )
                  })}
                </MarkerClusterGroup>
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="HAB Historical Data">
              <LayerGroup>
              <MarkerClusterGroup>
                {advisoryAPI.map((e, i) => {
                  return(
                    <FeatureGroup name={e.station_name} key={i}>
                      <Marker
                        position={{lat:e.latitude, lng:e.longitude}}
                        icon={e.has_hab ? redMarker : blueMarker}>
                        <Popup>
                          <div style={popupContent}>
                            <div style={popupHead}>
                              {e.station_name}
                            </div>
                            <div style={popupText}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Location:</td>
                                  <td>{e.longitude}, {e.latitude}</td>
                                </tr>
                                <tr>
                                  <td>Date Created:</td>
                                  <td>{e.timestamp}</td>
                                </tr>
                              </tbody>
                            </table>
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    </FeatureGroup>
                  )
                })}
              </MarkerClusterGroup>
              </LayerGroup>
            </LayersControl.Overlay>
           {isAuthenticated()
           &&
           <LayersControl.Overlay name="Forecast Status">
              <LayerGroup>
                <MarkerClusterGroup>
                  {habriskAPI.map((e, i) => {
                    return (
                      <FeatureGroup name={e.name} key={i}>
                          <Marker
                            position={{lat:e.latitude, lng:e.longitude}}
                            icon={greenMarker}>
                            <Popup>
                              <div style={popupContent}>
                                <div style={popupHead}>
                                  {e.name}
                                </div>
                                <div style={popupText}>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>Location:</td>
                                      <td>{e.longitude}, {e.latitude}</td>
                                    </tr>
                                    <tr>
                                      <td>Last Updated:</td>
                                      <td>{e.date_time}</td> {/* NOTE: USE e.date and e.time ONLY FOR SENSOR TIMESTAMP ONLY */}
                                    </tr>
                                    <tr>
                                      <td></td>
                                    </tr>

                                    {isAuthenticated()
                                    &&
                                    <tr>
                                      <td>Fishkill: </td>
                                      <td style={{display: '-webkit-box'}}>
                                        {
                                        e.fishkill_risk_level === 3 ?
                                          <Badge color="danger" className="mr-1">HIGH</Badge>
                                        : e.fishkill_risk_level === 2 ?
                                          <Badge color="warning" className="mr-1">MEDIUM</Badge>
                                        : e.fishkill_risk_level === 1 ?
                                          <Badge color="secondary" className="mr-1">LOW</Badge>
                                        :
                                          <Badge color="secondary" className="mr-1">N/A</Badge>
                                        }

                                      </td>
                                      <td>
                                        <div>
                                          <Tooltip title="Disclaimer: Predictions are contingent on input data, which have their own caveats." placement="bottom">
                                            <InfoIcon />
                                          </Tooltip>
                                        </div>
                                      </td>
                                    </tr>
                                    }
                                    {isAuthenticated()
                                    &&
                                    <tr>
                                      <td>Shellfish Toxicity: </td>
                                      <td style={{display: '-webkit-box'}}>
                                        {
                                        e.hab_risk_level === 3 ?
                                          <Badge color="danger" className="mr-1">HIGH</Badge>
                                        : e.hab_risk_level === 2 ?
                                          <Badge color="warning" className="mr-1">MEDIUM</Badge>
                                        : e.hab_risk_level === 1 ?
                                          <Badge color="secondary" className="mr-1">LOW</Badge>
                                        :
                                          <Badge color="secondary" className="mr-1">N/A</Badge>
                                        }

                                      </td>
                                      <td>
                                        <div>
                                          <Tooltip title="Disclaimer: Predictions are contingent on input data, which have their own caveats." placement="bottom">
                                            <InfoIcon />
                                          </Tooltip>
                                        </div>
                                      </td>
                                    </tr>
                                    }
                                  </tbody>
                                </table>
                                </div>
                              </div>
                            </Popup>
                          </Marker>
                      </FeatureGroup>
                    )
                  })}
                </MarkerClusterGroup>
              </LayerGroup>
            </LayersControl.Overlay>
            }
          </LayersControl>
        </MapContainer>
      </div>
    )
  }
}
