import L from 'leaflet';
import redMarkerPin from '../../images/red_marker_pin.png';
import blueMarkerPin from '../../images/blue_marker_pin.png';
import orangeMarkerPin from '../../images/orange_marker_pin.png'
import yellowMarkerPin from '../../images/yellow_marker.png'
import greenMarkerPin from '../../images/green_marker.png'
import cyanMarkerPin from '../../images/cyan_marker.png'

const redMarker = new L.Icon({
    iconUrl: redMarkerPin,
    iconRetinaUrl: redMarkerPin,
    iconSize: new L.Point(27, 27),
    iconAnchor: [17, 30]
});

const blueMarker = new L.Icon({
    iconUrl: blueMarkerPin,
    iconRetinaUrl: blueMarkerPin,
    iconSize: new L.Point(27, 27),
    iconAnchor: [17, 30]
});

const orangeMarker = new L.Icon({
    iconUrl: orangeMarkerPin,
    iconRetinaUrl: orangeMarkerPin,
    iconSize: new L.Point(27, 27),
    iconAnchor: [17, 30]
});

const yellowMarker = new L.Icon({
    iconUrl: yellowMarkerPin,
    iconRetinaUrl: yellowMarkerPin,
    iconSize: new L.Point(27, 27),
    iconAnchor: [17, 30]
});

const greenMarker = new L.Icon({
    iconUrl: greenMarkerPin,
    iconRetinaUrl: greenMarkerPin,
    iconSize: new L.Point(27, 27),
    iconAnchor: [17, 30]
});

const cyanMarker = new L.Icon({
    iconUrl: cyanMarkerPin,
    iconRetinaUrl: cyanMarkerPin,
    iconSize: new L.Point(27, 27),
    iconAnchor: [17, 30]
});


export { redMarker, blueMarker, orangeMarker, yellowMarker, greenMarker, cyanMarker };