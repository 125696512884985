import React, { useState, useEffect } from "react";
import axiosInstance from '../constants/axiosApi';
import {Link, Redirect} from 'react-router-dom';
import ResetPassword from './ResetPasswordPage';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="/">
        HABHub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ForgotPassword() {

  const [state, setState] = useState({
    email: "",
  });

  const [emailExists, setEmailExists] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const classes = useStyles();


  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const { email } = state;

    const response = await axiosInstance.get('api/users/',
      {
        params: {
          email: email
        },
        timeout: 0
      })
    .then(response => {
      if (response.data[0]) {
        setState({email: response.data[0].email});
        setLoading(false);
        setEmailExists(true);
      }
      else{
        console.log("Email DNE");
        setLoading(false);
      }
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);
    })
  };

  // useEffect(() => {
  // }, [submit]);


  if (emailExists) {
    return(
      <>
        <ResetPassword email={state.email}/>
        <Redirect to="/reset-password" />
      </>
    );
  }

  return(
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <VpnKeyOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <br />
        <Typography variant="body2" gutterBottom>
          Please enter your email address and your password will be reset and emailed to you.
        </Typography>
        <form className={classes.form} onSubmit={submit}>
          <TextField
            id="email"
            name="email"
            value={state.email}
            onChange={handleChange}
            label="Email Address"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/login" variant="body2">
                Remember your password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default ForgotPassword;
