import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import PropTypes from "prop-types";
import { loginUser } from "../redux/actions/auth";

import { Image, Carousel } from 'react-bootstrap';

import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://habhub.philhabs.net">
        HABHub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 'inherit'
  }
}));

/*START OF MANUAL USER SIGNUP VALIDATION CODE*/
const ManualSignUpValidation = (props) => {
  const [state, setState] = useState({
    email: "",
  });

  const classes = useStyles();

  return (
    <>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper elevation={3} className={classes.paper}>
      <div className={classes.paper}>
        <Image src="./images/email_manual.png" style={{maxWidth: "35%"}} />
        <Typography component="h1" variant="h5">
        <br />
          User Account Activation
        </Typography>
        <br />
        <Typography variant="subtitle1" display="block" align="center" gutterBottom>
          Your account has been successfully registered. The HABHub Admins will now process the activation of your account.
        </Typography>
        <br />
        <a href="http://habhub.philhabs.net">Home</a>
      </div>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </>
  );
};

export default ManualSignUpValidation;