import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import { logoutUser } from "../../redux/actions/auth";

const Header = (props) => {
  const history = useHistory();

  const { isLoggedIn } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await dispatch(logoutUser())
    .then(() => {
     window.location.reload();
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  return (
    <div>
      {isLoggedIn ?
        (<>
            <NavLink to="/">My Account</NavLink>
            {" | "}
            <NavLink to="/" onClick={handleLogout}>
              Logout
            </NavLink>
        </>)
        :
        (<>
          <NavLink to="/login">Login</NavLink>
          {" | "}
          <NavLink to="/signup">SignUp</NavLink>
        </>)
      }
    </div>
  );
};

export default Header;