import React from 'react';

import {
  Page,
  Grid,
  Card,
  Form,
  Button,
} from 'tabler-react';

import SiteWrapper from '../../SiteWrapper';
import {Link} from "react-router-dom";

function ContactInfo() {
  return (
  	<SiteWrapper>
  		<Page.Content>
  			<Grid.Row>
	       		<Grid.Col lg={9}>
	       			<Card statusColor="green">
	       				<Card.Header>
                    <Card.Title><b>CONTACT US</b></Card.Title>
                </Card.Header>
		       			<Card.Body>
		       			<Grid.Row>
		       				<Grid.Col lg={6}>
									  <Form.Group
									    isRequired
									    label="Full name"
									  >
									    <Form.Input name="example-text-input" />
									  </Form.Group>
									  <Form.Group
									    isRequired
									    label="Company"
									  >
									    <Form.Input name="example-text-input" />
									  </Form.Group>
									  <Form.Group
									    isRequired
									    label="Email"
									  >
									    <Form.Input name="example-text-input" />
									  </Form.Group>
									  <Form.Group label={<Form.Label>Message</Form.Label>}>
									  <Form.Textarea
									    defaultValue=""
									    name="example-textarea"
									    placeholder="Content.."
									    rows={6}
									  />
										</Form.Group>
										<div className="col text-center">
											<Button pill color="info">Submit</Button>
										</div>
									</Grid.Col>
									<Grid.Col lg={6}>
						        <div id="hab_project" className="container-fluid">
									  	<p></p>

										</div>
									</Grid.Col>
								</Grid.Row>
								</Card.Body>
	       			</Card>
	       		</Grid.Col>
	       		<Grid.Col lg={3}>
		       		<div className="sticky-top" id="abouthab-cards">
								<Card title="About:">
						      <Card.Body>
						      	<table className="ml-5 col text-md-left">
						      		<tr><Link className="nodecorate" to="/about-the-project" >The Project</Link></tr>
						        	<tr><Link className="nodecorate" to="/project-partners" >Project Partners</Link></tr>
						        	<tr><Link className="nodecorate" to="/contact-us" >Contact Us</Link></tr>
						        </table>
						      </Card.Body>
						    </Card>
							</div>
	       		</Grid.Col>
       	</Grid.Row>
  		</Page.Content>
  	</SiteWrapper>
  );
}

export default ContactInfo;
