import React, {Component} from 'react';
import axiosInstance from './constants/axiosApi';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from "./authentication/PrivateRoute";
import "tabler-react/dist/Tabler.css";

/*
 * HAB COMPONENT IMPORTS
 */

/*HAB Landing Page*/
import HabPortal from './components/1_HabPortal';

/*HAB Info*/
import HarmfulAlgalBlooms from './components/2_HabInfo';

/*HAB Tools*/
import DataCatalogPage from './components/3_HabTools/DataCatalogPage';

import DataCatalog from './components/3_HabTools/DataCatalog';
import DatasetPage from './components/3_HabTools/DatasetPage';
import HabDataPortal from './components/3_HabTools/HabDataPortal';
import ModelsAndForecasts from './components/3_HabTools/ModelsAndForecasts';
import SeahabsViewer from './components/3_HabTools/SeahabsViewer';
import SensPak from './components/3_HabTools/SensPak';

/*HAB Advisory*/
import HabAdvisory from './components/4_HabAdvisory';

/*Partnerships*/
import CoastsAndHabs from './components/5_Partnerships';

/*Data Contribution*/
import DataContribution from './components/6_DataContribution';

/*About*/
import AboutTheProject from './components/7_AboutHab/AboutTheProject';
import AboutTheOrganization from './components/7_AboutHab/AboutTheOrganization';
import ProjectPartners from './components/7_AboutHab/ProjectPartners';
import ContactUs from './components/7_AboutHab/ContactUs';

/*Reusables components*/
import Timeseries from './components/reusables/timeseries';

//import Login from './pages/Login';
import ProfilePage from "./components/userProfile/ProfilePage";
import Login from './pages/LoginPage';
import SignUp from './pages/SignUpPage';
import Logout from './pages/Logout';
import Empty from './pages/Empty';
import Error404 from './pages/404';
import ForgotPassword from './pages/ForgotPasswordPage';
import ResetPassword from './pages/ResetPasswordPage';
import EmailValidation from './pages/EmailValidationPage';
import ManualSignUpValidation from './pages/ManualSignUpValidationPage';
import ScrollToTop from "./utilities/ScrollToTop";

/*type Props = {||};*/

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url : 'localhost',
      /*url : '10.199.20.25'*/
    };
  }
  render(){
    //Magic bura console
    console.log = () => {};
    return(
        <React.StrictMode>
          <Router basename="/static">
            <ScrollToTop />
            <Switch>
              /*HAB Info*/
              <Route exact path="/harmful-algal-blooms" component={HarmfulAlgalBlooms} />
              <Route exact path="/coasts-and-habs" component={CoastsAndHabs} />

              /*HAB Tools*/

              <Route exact path="/data-catalog" component={DataCatalogPage} />

              {/* <Route exact path="/data-catalog" component={DataCatalog} /> */}
              {/* <Route exact path="/datasets/:topic" component={DatasetPage} /> */}
              <Route exact path="/data-portal" component={HabDataPortal} />
              <Route exact path="/seahabs-viewer" component={SeahabsViewer} />
              <Route exact path="/senspak" component={SensPak} />
              <Route exact path="/models-and-forecasts" component={ModelsAndForecasts} />

              /*HAB Advisory*/
              <Route exact path="/hab-advisory" component={HabAdvisory} />

              /*Data Contribution*/
              <Route exact path="/data-contribution" component={DataContribution} />

              /*About*/
              <Route exact path="/about-the-project" component={AboutTheProject} />
              <Route exact path="/about-the-organization" component={AboutTheOrganization} />
              <Route exact path="/project-partners" component={ProjectPartners} />
              <Route exact path="/contact-us" component={ContactUs} />

              /*Reusables components*/
              <Route exact path="/timeseries" component={Timeseries} />
              <Route exact path="/empty" component={Empty} />

              /*User Authentication Pages*/
              <PrivateRoute exact path="/profile" component={ProfilePage}/>
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ForgotPassword} />
              /*<Route exact path="/reset-password:email" render={(props) => (<ResetPassword {...props} />)} />*/
              <Route exact path="/email-validation" component={EmailValidation} />
              <Route exact path="/manual-signup-validation" component={ManualSignUpValidation} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/logout" component={Logout} />

              /*HAB Portal*/
              <Route exact path="/" component={HabPortal} />

              /*Error 404 page*/
              <Route component={Error404} />
            </Switch>
          </Router>
        </React.StrictMode>
    );
  }
}

export default App;
