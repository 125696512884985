import * as React from 'react'
import {Link, NavLink, withRouter} from 'react-router-dom'
import Header from "./components/reusables/Header";

import {
  Site,
  Nav,
  Grid,
  List,
  RouterContextProvider,
} from 'tabler-react';

import type { NotificationProps } from 'tabler-react';

type Props = {|
  +children: React.Node,
|};

type subNavItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +LinkComponent?: React.ElementType,
  +useExact?: boolean,
|};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +active?: boolean,
  +LinkComponent?: React.ElementType,
  +subItems?: Array<subNavItem>,
  +useExact?: boolean,
|};

const navBarItems: Array<navItem> = [
  {
    value: "HAB Portal",
    to: "/",
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: "HAB Info",
    to: "/harmful-algal-blooms",
    icon: "info",
    LinkComponent: withRouter(NavLink),
  },
  {
    value: "HAB Tools",
    icon: "map-pin",
    subItems: [
      { value: "Data Catalog", to: "/data-catalog", LinkComponent: withRouter(NavLink) },
      { value: "HAB Data Portal", to: "/data-portal", LinkComponent: withRouter(NavLink) },
      { value: "Models and Forecasts", to: "/models-and-forecasts", LinkComponent: withRouter(NavLink) },
      { value: "SeAHABS Viewer", to: "/seahabs-viewer", LinkComponent: withRouter(NavLink) },
      { value: "Sensors", to: "/senspak", LinkComponent: withRouter(NavLink) },
    ],
  },
  {
    value: "HAB Advisory",
    to: "/hab-advisory",
    icon: "alert-triangle",
    LinkComponent: withRouter(NavLink),
  },
  {
    value: "Data Contribution",
    to: "/data-contribution",
    icon: "upload-cloud",
    LinkComponent: withRouter(NavLink),
  },
  {
    value: "Partnerships",
    to: "/coasts-and-habs",
    icon: "users",
    LinkComponent: withRouter(NavLink),
  },
  {
    value: "About",
    icon: "thumbs-up",
    subItems: [
      { value: "About HABHub", to: "/about-the-project", LinkComponent: withRouter(NavLink) },
      { value: "Project Partners", to: "/project-partners", LinkComponent: withRouter(NavLink) },
      { value: "Contact Us", to: "/contact-us", LinkComponent: withRouter(NavLink) },
    ],
  },
];

const accountDropdownProps = {
  avatarURL: "/images/admin_poring.jpg",
  name: "HAB Admin",
  description: "Administrator",
  options: [
    { icon: "user", value: "Profile", to: "/profile/"},
    { icon: "settings", value: "Settings", to: "/login/" },
    /*{ icon: "mail", value: "Inbox", badge: "6" },
    { icon: "send", value: "Message", to: "/login/" },*/
    { isDivider: true },
    { icon: "help-circle", value: "Need help?", to: "/login/" },
    { icon: "log-out", value: "Sign out", to: "/logout/"},
  ],
};

class SiteWrapper extends React.Component {

  render(){
    return(
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: "habs_logo",
          imageURL: "/images/HABHub_logo_transparent.png",
        /*children:<Header/>*/
        navItems: (
              <Nav.Item type="div" className="d-none d-md-flex">
                <Header/>
              </Nav.Item>
          ),
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          copyright: (
            <React.Fragment>
              Copyright © 2020
              <a href="."> HABHub</a>.
              All rights reserved.
            </React.Fragment>
          ),
          nav: (
            <React.Fragment>
              <Grid.Col auto={true}>
                <List className="list-inline list-inline-dots mb-0">
                  <List.Item className="list-inline-item">
                    <Link to="/">Data Privacy Policy</Link>
                  </List.Item>
                  <List.Item className="list-inline-item">
                    <Link to="/contact-us">Contact Us</Link>
                  </List.Item>
                </List>
              </Grid.Col>
            </React.Fragment>
          ),
        }}
      >
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

export default SiteWrapper;
