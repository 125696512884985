import React from 'react';
import axiosInstance from '../../constants/axiosApi';
import { Link } from "react-router-dom";
import {Badge} from 'tabler-react';
import Tooltip from '@material-ui/core/Tooltip';
import {
  FeatureGroup,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from 'react-leaflet'
import { redMarker, blueMarker, orangeMarker, yellowMarker, greenMarker, cyanMarker } from '../reusables/mapMarker';
import { popupContent, popupHead, popupText } from "../reusables/popupStyles";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { isAuthenticated } from '../../constants/authApi';


export default class MapAdvisory extends React.Component<{}, State> {

  constructor(props) {
    super(props);
    this.state = {
      center: [12.599512, 121.984222],
      zoom: 5,
      station: [],
      embayment: [],
    };
  }

  componentDidMount() {
    this.loadMapLayers();
    /*this.interval = setInterval(this.loadMapLayers(), 3000);*/
  }

  async loadMapLayers() {
    await axiosInstance.get('api/sensor-latest-data/')
    .then(res => {
      const station = res.data;
      this.setState({ station });
    })
    .catch(function (error) {
    console.log(error);
    })
    await axiosInstance.get('api/embayment/')
    .then(res => {
      const embayment = res.data;
      this.setState({ embayment });
    })
    .catch(function (error) {
    console.log(error);
    })
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.station !== this.props.station) {
      this.timer = setTimeout(this.loadMapLayers(), 3000);
    }

    /*this.timer = setTimeout(this.loadMapLayers(), 3000);*/
    /*setTimeout(this.loadMapLayers(), 3000);*/
    /*this.interval = setInterval(this.loadMapLayers(), 3000);*/
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearTimeout(this.timer);
  }

  render() {
    const {station} = this.state;
    const {embayment} = this.state;
    
    return (
      <MapContainer className="leaflet_advisory_map" center={this.state.center} zoom={this.state.zoom} scrollWheelZoom={false}>
        <LayersControl position="topright" collapsed={false}>
          <LayersControl.BaseLayer checked name="ESRI World Imagery">
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="ESRI World Street Map">
            <TileLayer
              attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
              url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.Overlay checked name="Sensor Observations">          
            <LayerGroup>
              <MarkerClusterGroup>
              {station.map((station, i) => {
                return(
                  <FeatureGroup name={station.station_name} key={i}>
                    <Marker
                      position={{lat:station.latitude, lng:station.longitude}}
                      icon={greenMarker}>
                      <Popup>
                        <div style={popupContent}>
                          <div style={popupHead}>
                            {station.name}
                          </div>
                          <div style={popupText}>
                          <table>
                            <tbody>
                              <tr>
                                <td>Location:</td>
                                <td>{station.longitude}, {station.latitude}</td> 
                              </tr>
                              <tr>
                                <td>Last Updated:</td>
                                <td>{station.date_time}</td> 
                              </tr>
                            </tbody>
                          </table>
                          </div>
                        </div>
                        <div className="col text-center">
                          <Link to={`/data-portal`}>
                            view sensor data
                          </Link>
                        </div>
                      </Popup>
                    </Marker>
                  </FeatureGroup>
                )
              })}
              </MarkerClusterGroup>
            </LayerGroup>  
          </LayersControl.Overlay>
          {isAuthenticated()
            ?
            <LayersControl.Overlay name="Shellfish Advisory">
              <LayerGroup>
                {embayment.map((e, i) => {
                  return( 
                    <FeatureGroup name={e.embayment_name} key={i}>
                      <Marker 
                        position={{lat:e.latitude, lng:e.longitude}}
                        icon={blueMarker}>
                        <Popup>
                          <div style={popupContent}>
                            <div style={popupHead}>
                              {e.embayment_name}
                            </div>
                            <div style={popupText}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Shellfish Advisory:</td>
                                  <td>{e.shellfish_advisory}</td> 
                                </tr>
                                <tr>
                                  <td>Hab Risk:</td>
                                  <td>{e.hab_risk}</td> 
                                </tr>
                              </tbody>
                            </table>
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    </FeatureGroup>
                  )
                })}
              </LayerGroup>
            </LayersControl.Overlay>
            :null
          }
        </LayersControl>
      </MapContainer>
    )
  }
}
