import { 
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  SET_MESSAGE,
} from "./types";

import { obtainToken, logout } from "../../constants/authApi";


export function setMessage(message) {
  return { 
    type: SET_MESSAGE, 
    payload: message
  };
}

export function loginUserSuccess(accessToken) {
  return { 
    type: LOGIN_USER_SUCCESS, 
    payload: accessToken,
  };
}

export function loginUserFail() {
  return { type: LOGIN_USER_FAIL };
}

export function logoutUserSuccess() {
  return { type: LOGOUT_USER };
}

export function logoutUser() {
  return async function (dispatch) {
    await logout();
    dispatch(logoutUserSuccess());
  };
}

export const loginUser = (email, password) => (dispatch) => {
  return obtainToken(email, password).then(
    (data) => {
      console.log(data.status);
      console.log(data.data);
      dispatch(loginUserSuccess(data.data.access));

      return Promise.resolve();
    },
    (error) => {
      /*const message = error.response.data.detail.toString();*/
      const message = "Invalid email and/or password"
      dispatch(loginUserFail());
      dispatch(setMessage(message));

      return Promise.reject();
    });  
}
