import * as types from "../actions/types";
import initialState from "./initialState";

/*Get access_token from local storage*/
const access_token = initialState.accessToken;

/*Check if there is access_token in local storage,
if true isLoggedIn set to true else set to false*/
const baseState = access_token
  ? { isLoggedIn: true, access_token }
  : { isLoggedIn: false, access_token: null };

export default function (state = baseState, action) {
	const { type, payload } = action;

  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
      return{
      	...state,
        isLoggedIn: true,
        access_token: payload,
        /*access_token: payload.token,*/
      	/*action.token*/
      };
    case types.LOGIN_USER_FAIL:
      return{
      	...state,
        isLoggedIn: false,
        access_token: null,
      };
    case types.REGISTER_USER_SUCCESS:
      return{
      	 ...state,
        isLoggedIn: false,
      };
    case types.REGISTER_USER_FAIL:
      return{
      	...state,
        isLoggedIn: false,
      };
    case types.LOGOUT_USER:
      return{
      	...state,
        isLoggedIn: false,
        access_token: null,
      };
    default:
      return state;
  }
}