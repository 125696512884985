import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../constants/axiosApi';
import CsvDownloader from 'react-csv-downloader';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import {
  Form,
  Card,
  Button,
  Grid,
} from 'tabler-react';

const options = [
    {
      label: 'Biological Data',
      options: [
        { value: 'cdom', label: 'Colored Dissolved Organic Matter (CDOM)' },
        { value: 'chl_a', label: 'Chlorophyll-A' },
        { value: 'doc', label: 'Dissolved Organic Carbon' },
        { value: 'flourescence', label: 'Pigment Concentration' },
      ],
    },
    {
      label: 'Environmental Data',
      options: [
        { value: 'nh4', label: 'Ammonia Concentration' },
        { value: 'do', label: 'Dissolved Oxygen' },
        { value: 'no3', label: 'Nitrate' },
        { value: 'nox', label: 'Nitrate+Nitrite Concentration' },
        { value: 'no2', label: 'Nitrite' },
        { value: 'sio4', label: 'Orthosilicate' },
        { value: 'ph', label: 'pH' },
        { value: 'po4', label: 'Phosphate' },
        //{ value: 'salinity', label: 'Salinity' },
        //Changed label to salinity, variable for conductivity based on sensor data
        { value: 'conductivity', label: 'Salinity' },
        { value: 'pressure', label: 'Seawater Pressure' },
        { value: 'sio2', label: 'Silica' },
        { value: 'temperature', label: 'Temperature' },
        { value: 'tin', label: 'Total Inorganic Nitrogen' },
        { value: 'p', label: 'Total Phosphorous Concentration' },
        { value: 'tss', label: 'Total Suspended Solids' },
        { value: 'turbidity', label: 'Turbidity' },
        { value: 'density', label: 'Water Density' },
        // { value: 'source', label: 'Data Source' },
      ],
    },
    {
      label: 'Shellfish Data',
      options: [
        { value: 'toxicity', label: 'Toxicity' },
        { value: 'toxin', label: 'Toxin' },

      ],
    },
    {
      label: 'Forecast Model Results',
      options: [
        { value: 'pmr', label: 'Predictive Model Result' },
      ],
    }
	];

class AvailableData extends React.Component {

	constructor(props){
	  super(props);
	  this.state = {
      embayment: [],
      selectEmbayment: "All Sites",
      station:[],
      selectStation: "All Stations",
      tempStation: [],
      sampleData: [],
      exportSampleData: [],
	    selected: [],
    	isLoading: false
	  };
	  /*this.downloadData = this.downloadData.bind(this);*/
    this.csvLink = React.createRef();
  }

  onChange = (selected) => {
    this.setState({ selected });
  };

  /*async downloadData(){
  	this.setState({ isLoading: true });
    await axiosInstance.get('api/download-sample-data/?ordering=-sample_date')
    .then(res => {
      this.setState({exportData: res.data, isLoading: false});
      console.log(this.state.exportData);
    })
    .catch(function (error) {
    	this.setState({isLoading: false });
	    console.log(error);
    })
  }*/

  async componentDidMount(){
    await axiosInstance.get('api/embayment/')
    .then(res => {
      const embayment = res.data;
      console.log(embayment);
      this.setState({ embayment });
    })
    .catch(function (error) {
    console.log(error);
    })

    await axiosInstance.get('api/station/')
    .then(res => {
      const station = res.data;
      this.setState({ station });
      this.setState({ tempStation: station });
    })
    .catch(function (error) {
    console.log(error);
    })

    await axiosInstance.get('api/download-sample-data/?ordering=-sample_date',
    {
      timeout: 0
    })
    .then(res => {
      const sampleData = res.data;
      this.setState({sampleData});
      this.setState({
        exportSampleData: sampleData
      });
    })
    .catch(function (error) {
    console.log(error);
    })
  }

  DownloadData = async() => {
    var exData = this.state.exportSampleData;
    var sample_info = ["id", "station_name", "embayment", "sample_date", "sample_depth", "sample_num"];
    var keys_to_keep = sample_info.concat(this.state.selected);

    const redux = array => array.map(o => keys_to_keep.reduce((acc, curr) => {
      acc[curr] = o[curr];
      return acc;
    }, {}));

    exData = redux(exData);

    console.log(exData, "exData");
    this.setState({
      exportSampleData: exData
    });
    // this.csvLink.current.link.click()
    // this.setState({ isLoading: true });
    // var result = [];
    // // Check if embayment is set default = All Areas
    // if(this.state.selectEmbayment == "All Areas"){
    //   // Check if station is set default = "All Sites"
    //   if(this.state.selectStation == "All Sites"){
    //       //Export default whole sample data table
    //       result = this.state.sampleData
    //   }
    //   else // Export selected specific site's samples as csv
    //   {
    //     result = this.state.sampleData.filter(e => {return e.station_name == this.state.selectStation});
    //   }
    // }
    // else // Filter based on specific embayment
    // {
    //   result = this.state.sampleData.filter(e => {return e.embayment == this.state.selectEmbayment});
    //   // Check if station is set default = "All Sites"
    //   if(this.state.selectStation !== "All Sites"){
    //     result = result.filter(e => {return e.station_name == this.state.selectStation});
    //   }
    // }
    // // Export sample data
    // this.setState({
    //   exportSampleData: result,
    //   isLoading: false
    // });
  }

  HandleEmbaymentChange = (event) => {
    this.setState({
      selectEmbayment: event.target.value
    });
    // Change Station dropdown to include only station from current embayment
    // Also filter sampleData based on selected embayment
    var result = [];
    var exData = [];
    if (event.target.value == "All Sites"){
      result = this.state.station
      exData = this.state.sampleData
    }else{
      result = this.state.station.filter(e => {return e.embayment == event.target.value});
      exData = this.state.sampleData.filter(e => {return e.embayment == event.target.value});
    }
    // Save corresponding sites to tempStation
    this.setState({
      tempStation: result,
      exportSampleData: exData
    });
  }

  HandleStationChange = (event) => {
    this.setState({
      selectStation: event.target.value
    });
    var exData = [];
    if (event.target.value == "All Stations"){
      exData = this.state.exportSampleData
    }else{
      exData = this.state.exportSampleData.filter(e => {return e.station_name == event.target.value});
    }
    // Save corresponding site(s) to exportSampleData
    this.setState({
      exportSampleData: exData
    });
  }

  render(){
    //  console.log(this.state.selectEmbayment, "selectEmbayment");
    //  console.log(this.state.tempStation, "tempStation");
    //  console.log(this.state.selectStation, "selectStation");
    //  console.log(this.state.exportSampleData, "exportSampleData");
    // console.log(this.state.sampleData, "sampleData");
    console.log(this.state.selected, "selected");
  	return(
	  	<Card>
	      <Card.Header>
	        <Card.Title>Available Data</Card.Title>
	      </Card.Header>
	      <Card.Body>
          <Grid.Row>
            <Grid.Col width={6}>
              <Form.Group label="Site">
                <Form.Select
                  id="embayment"
                  name="embayment"
                  onChange={this.HandleEmbaymentChange}
                >
                  <option value="All Sites">All Sites</option>
                  {Object.values(this.state.embayment).map((embayment, i) => {
                    return(
                      <React.Fragment key={i}>
                        <option value={embayment.embayment_name}>{embayment.embayment_name}</option>
                      </React.Fragment>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={6}>
              <Form.Group label="Station">
                <Form.Select
                  id="station"
                  name="station"
                  onChange={this.HandleStationChange}
                >
                  <option value="All Stations">All Stations</option>
                  {Object.values(this.state.tempStation).map((station, i) => {
                    return(
                      <React.Fragment key={i}>
                        <option value={station.station_name}>{station.station_name}</option>
                      </React.Fragment>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Form.Group label="Variable Types">
            <DualListBox style={{'overflow-y': 'scroll'}} canFilter options={options} selected={this.state.selected} onChange={this.onChange} />
          </Form.Group>
	      </Card.Body>
	      <Card.Footer>
	        <div className="col text-center">
	          <CsvDownloader datas={this.state.exportSampleData} filename="habdata">
              <Button pill color="info" type="button" icon="download" onClick={this.DownloadData} disabled={this.state.isLoading}>Download</Button>
            </CsvDownloader>
	        </div>
	      </Card.Footer>
	    </Card>
  	);
  }
}

export default AvailableData;
