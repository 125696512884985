import React from 'react';

import {
  Page,
  Grid,
  Card,
} from 'tabler-react';

import SiteWrapper from '../../SiteWrapper';
import {Link} from "react-router-dom";

function ProjectPartners() {
  return (
  	<SiteWrapper>
  		<Page.Content>
  			<Grid.Row>
	       		<Grid.Col lg={9}>
	       			<Card statusColor="green">
	       				<Card.Header>
                    <Card.Title><b>PROJECT PARTNERS</b></Card.Title>
                </Card.Header>
		       			<Card.Body>
					        <div className="container-fluid">
					        	<p><Link to="/coasts-and-habs">COASTS</Link> or Community Alliance for the Sustainability of our Threatened Seas hopes to help address the risks from harmful algal blooms and pollution by providing ways to more rapidly detect the conditions and organisms leading to HABs and eventually develop an early-warning system that can be used by shellfish and fish farmers and government agencies.</p>

				        		<p>Currently, there are three pilot sites in Samar, Capiz and Bolinao, which were chosen based on their different HAB types and their importance in contributing to seafood production. At each site, local fishers, other stakeholders and government units are engaged in various ways:</p>
				        		<ul>
									  	<li>Discussions to better understand their risks from HABs</li>
									  	<li>Trainings for increased awareness about HABs, and to develop their capacity for monitoring and response</li>
									  	<li>Active participation in monitoring water quality and HABs</li>
								  	</ul>
							  		<p>Through these efforts, the COASTS program hopes not only to help mitigate the harmful effects of HABs but also to build a network of partners working towards improving food safety, livelihood security, and ultimately ensuring the quality of our waters.</p>
					        	<h4><u>Community and People’s Organizations</u></h4>
					        	<ul>
											<li>Samahan ng Maka-kalikasang Mangingisda ng Victory (Bolinao, Pangasinan)</li>
											<li>Bolinao Integrated Aquaculturist Association (BIAA)</li>
											<li>KAISAKA (Bolinao, Pangasinan)</li>
											<li>Lonoy Agrarian Cooperative (Brgy. Lonoy, Roxas City)</li>
											<li>Maligaya Business Association (Jiabong, Samar)</li>
										</ul>
										<h4><u>Government Agencies</u></h4>
										<ul>
											<li>
												<a href="https://www.bfar.da.gov.ph/" target="_blank" rel="noopener noreferrer">
												BFAR Central Office
												</a>
											</li>
											<li>
												<a href="https://www.bfar.da.gov.ph/" target="_blank" rel="noopener noreferrer">
												BFAR National Fisheries Laboratory Division
												</a>
											</li>
											<li>
												<a href="https://region1.bfar.da.gov.ph/" target="_blank" rel="noopener noreferrer">
												BFAR Region I
												</a>
											</li>
											<li>
												<a href="https://region6.bfar.da.gov.ph/" target="_blank" rel="noopener noreferrer">
												BFAR Region VI
												</a>
											</li>
											<li>
												<a href="https://region8.bfar.da.gov.ph/" target="_blank" rel="noopener noreferrer">
												BFAR Region VIII
												</a>
											</li>
											<li>Provincial Government of Capiz</li>
											<li>Municipal Government of Sapian</li>
											<li>Municipal Government of Jiabong</li>
											<li>Municipal Government of Bolinao</li>
										</ul>
										<h4><u>Academic Institutions</u></h4>
										<ul>
											<li>
												<a href="http://www.psu.palawan.edu.ph/" target="_blank" rel="noopener noreferrer">
												Palawan State University
												</a>
											</li>
											<li>
												<a href="https://tac.upv.edu.ph/" target="_blank" rel="noopener noreferrer">
												University of the Philippines Visayas Tacloban College
												</a>
											</li>
										</ul>
									</div>
								</Card.Body>
	       			</Card>
	       		</Grid.Col>
	       		<Grid.Col lg={3}>
		       		<div id="abouthab-cards">
								<Card title="About:">
						      <Card.Body>
						      	<table className="ml-5 col text-md-left">
						      		<tr><Link className="nodecorate" to="/about-the-project" >The Project</Link></tr>
						        	<tr><Link className="nodecorate" to="/project-partners" >Project Partners</Link></tr>
						        	<tr><Link className="nodecorate" to="/contact-us" >Contact Us</Link></tr>
						        </table>
						      </Card.Body>
						    </Card>
							</div>
	       		</Grid.Col>
       	</Grid.Row>
  		</Page.Content>
  	</SiteWrapper>
  );
}

export default ProjectPartners;
