import React from "react";

import SiteWrapper from "../SiteWrapper";

function Empty() {
  return(
	  <SiteWrapper>
	  </SiteWrapper>
  )
}

export default Empty;