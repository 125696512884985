import React, { useState, useEffect } from 'react';
import { Document, Page as PdfPage, pdfjs } from 'react-pdf';
import Calendar from 'react-calendar';
import axiosInstance from '../../constants/axiosApi';
import { API_URL } from '../../constants/index.js';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';

import { 
  Page, 
  Grid,
  Card,
  Button,
} from 'tabler-react';

import { ListGroup } from 'react-bootstrap';
import SiteWrapper from '../../SiteWrapper';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function HabAdvisory() {
  const [value, setValue] = useState(new Date());   /*date specific for calendar*/
  const [pageNumber, setPageNumber] = useState(1);  /*react-pdf constant*/
  const [numPages, setNumPages] = useState(null);
  /*API Queries*/
  const [pdfFile, setPdfFile] = useState({});       /*API => store all pdf files on object*/
  const [url, setUrl] = useState();                 /*API => store url of newest data*/
  const [annualPdf, setAnnualPdf] = useState({});   /*API => store all annual pdf files on object*/

  useEffect(() => {
    const fetchData = async () => {
      const results = await axiosInstance(
        'api/shellfish-bulletin/?ordering=-published',
      );
      setPdfFile(results.data);         /*store all pdf data on object 'pdfFile'*/
      setUrl(results.data[0].file);     /*store url of newest pdf file on 'url'*/
    };
    const fetchAnnualData = async () => {
      const results = await axiosInstance(
        'api/annual-shellfish-bulletin/?ordering=-year',
      );
      setAnnualPdf(results.data);       /*store all pdf data on object 'pdfFile'*/
    };

    fetchData();
    fetchAnnualData();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onChange(nextValue) {
    setValue(nextValue);
    if(bulletin_dates.includes(moment(nextValue).format("YYYY-MM-DD"))){
      var filteredUrl = Object.values(pdfFile).filter(item => item.published.includes(moment(nextValue).format("YYYY-MM-DD"))).map(e => e.file);
      filteredUrl = Object.assign( {}, filteredUrl);
      setUrl(filteredUrl[0]);
    }  
  }

  function yearQuery(url) {
    setUrl(url);
  }

  /*Populates react-calendar with redtide bulletin advisory dates*/
  const bulletin_dates = Object.values(pdfFile).map(e => e.published);

  /*Redtide bulletin PDF file links*/
  const bulletin_urls = Object.values(pdfFile).map((e) => e.file);

  /*console.log(moment(value).format("YYYY-MM-DD"));*/
  return (
    <SiteWrapper>
      <Page.Content title="Harmful Algal Bloom Advisory">
        <Grid.Row>
          <Grid.Col lg={8}>
            <Card>
              <div className="all-page-container">
                <Document
                  file={url}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                 {Array.from(new Array(numPages), (el, index) => (
                    <PdfPage key={`page_${index + 1}`} pageNumber={index + 1} scale = '1.2' />
                  ))}
                </Document>
              </div>
              <Card.Footer>
                <a href="https://www.bfar.da.gov.ph/index.jsp" target="_blank" rel="noopener noreferrer">View in BFAR Website </a>
              </Card.Footer>
            </Card>
          </Grid.Col>
          <Grid.Col lg={4}>
            <Grid.Row>
              <Grid.Col lg={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>View Previous Advisories</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Calendar
                      calendarType={"US"}
                      onChange={onChange}
                      value={value}
                      tileClassName={({ date, view }) => {
                      if(bulletin_dates.find(x=>x===moment(date).format("YYYY-MM-DD"))){
                       return  'highlightCalendar'
                      }
                    }}
                    />
                  </Card.Body>
                </Card>
              </Grid.Col>
              <Grid.Col lg={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>Compiled Annual Shellfish Bulletin</Card.Title>
                  </Card.Header>
                  <ListGroup variant="flush" style={{alignItems:'stretch',textAlign:'center'}}>
                    { Object.values(annualPdf).map((annualpdf, i) => {
                      return(
                        <ListGroup.Item key={i}><Button link onClick={() => {yearQuery(annualpdf.file)}}>{annualpdf.year}</Button></ListGroup.Item>
                      )
                    })}
                  </ListGroup>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default HabAdvisory;
