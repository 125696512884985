import React from 'react';
import { Link } from "react-router-dom";
import { 
	Card,
  Table,
  Button,
  Icon, 
} from 'tabler-react';

function SubmitMonDataNoAuth(){

	return(
		<>
			<Card.Body>
				<p>Please <Link to="/login">login</Link> to submit datasets.</p>
			</Card.Body>
			<Card.Footer>
	      <div className="col text-center">
		      <Link to="/login">
		      	<Button pill color="info" icon="login">Login</Button>
		      </Link>     
	      </div>
	    </Card.Footer>
    </>
	);
}

export default SubmitMonDataNoAuth;