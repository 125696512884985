import React, { useState, useEffect } from 'react';
import axiosInstance from '../../constants/axiosApi';
import axiosInstanceNoAuth from '../../constants/axiosNoAuthApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { isAuthenticated } from '../../constants/authApi';
import SubmitMonDataNoAuth from './SubmitMonDataNoAuth';
import SubmitMonDataAuth from './SubmitMonDataAuth';

import {
  Page,
  Grid,
  Container,
  Table,
  Card,
  Button,
  Badge,
  Icon,
  Form,
} from 'tabler-react';

import SiteWrapper from '../../SiteWrapper';
import {Link} from "react-router-dom";


function DataContribution(){
  const [state, setState] = useState({
    contributor: "",
    email: "",
    contact_number: "",
    location: "",
    incident_type: "WATER DISCOLORATION",
    about_incident: "",
  });

  const [image_file, set_image_file] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleFileChange = event => {
    if (event.target.files.length) {
      set_image_file(event.target.files[0]);
    }
  };

  const clearFields = () => {
    /*Resets all input fields*/
    Array.from(document.querySelectorAll("input")).forEach(
    input => (input.value = "")
    );
    /*Resets "about_incident" textarea since
      it can't be reset using setState only!*/
    Array.from(document.querySelectorAll("textarea")).forEach(
    textarea => (textarea.value = "")
    );
    setState({
      contributor: "",
      email: "",
      contact_number: "",
      location: "",
      incident_type: "WATER DISCOLORATION",
      about_incident: "",
   });
    set_image_file("");
  }

  const submit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('contributor', state.contributor);
    formData.append('email', state.email);
    formData.append('contact_number', state.contact_number);
    formData.append('location', state.location);
    formData.append('images', image_file);
    formData.append('incident_type', state.incident_type);
    formData.append('about_incident', state.about_incident);

    const response = await axiosInstanceNoAuth.put('/data-contribution/hab-upload/', formData,
      {
        timeout: 0
      })
    .then(response => {
      console.log(response.status);
      if (response.status === 201) {
        toast.success("Your report has been received! Thank you!",);
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error("Sorry, error on submission.",);
    })
    clearFields();
  };

  return (
    <SiteWrapper>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        closeOnClick
        newestOnTop
      />
      <Page.Content>
      <Grid.Row>
        <Grid.Col lg={12}>
          <Form.Group>
            <Form.StaticText>
            <p>We believe that scientific information should be accessible as best as possible. Should you want to inquire about helping to further the dissemination of scientific information and contributing data especially for oceanography and HAB events, please contact us  at <a href="mailto:habhub@msi.upd.edu.ph">habhub@msi.upd.edu.ph</a>.</p>
            </Form.StaticText>
          </Form.Group>
          <Card>
            <Card.Header>
              <Card.Title><strong>Submit Data</strong></Card.Title>
            </Card.Header>
              {isAuthenticated()
                ?<SubmitMonDataAuth />
                :<SubmitMonDataNoAuth />
              }
          </Card>
        </Grid.Col>
        <Grid.Col lg={12}>
        <Card>
          <Form onSubmit={submit}>
            <Card.Header>
              <Card.Title><strong>Report a HAB Incident</strong></Card.Title>
            </Card.Header>
            <Card.Body>
              <p>Report a HAB-related incident (i.e. water discoloration, fish kill, poisoning occurence). To contribute
              other information <Link to="/contact-us"><u>contact us here</u></Link>.</p>
              <Grid.Row>
                <Grid.Col width={4}>
                  <Form.Group
                    label="Contributor"
                  >
                    <Form.Input
                      id="contributor"
                      name="contributor"
                      value={state.contributor}
                      onChange={handleChange}
                      placeholder="Last Name, First Name, M.I."
                      type="text"
                      required={true}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={4}>
                  <Form.Group
                    label="Email address"
                    autoComplete="email"
                  >
                    <Form.Input
                      id="email"
                      name="email"
                      value={state.email}
                      onChange={handleChange}
                      placeholder="example@email.com"
                      type="email"
                      required={true}
                      autoComplete="on"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={4}>
                  <Form.Group
                    label="Contact number"
                  >
                    <Form.Input
                      id="contact_number"
                      name="contact_number"
                      value={state.contact_number}
                      onChange={handleChange}
                      placeholder="Landline / Mobile No."
                      type="text"
                      required={true}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={4}>
                  <Form.Group
                    label="Location of incident"
                  >
                    <Form.Input
                      id="location"
                      name="location"
                      value={state.location}
                      onChange={handleChange}
                      placeholder="e.g. Manila Bay, GPS coordinate"
                      type="text"
                      required={true}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={4}>
                  <Form.Group label="Type of incident">
                    <Form.Select
                      id="incident_type"
                      name="incident_type"
                      value={state.incident_type}
                      onChange={handleChange}
                      required={true}
                    >
                      <option value="WATER DISCOLORATION">Water Discoloration</option>
                      <option value="FISH KILL">Fish Kill</option>
                      <option value="POISONING">Poisoning</option>
                    </Form.Select>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={4}>
                  <Form.Group label="Upload photos (.png/.jpeg)">
                   <input
                      type="file"
                      id="image_file"
                      name="image_file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={12}>
                  <Form.Group label="Tell us about the incident">
                    <Form.Textarea
                      id="about_incident"
                      name="about_incident"
                      value={state.about_incident}
                      onChange={handleChange}
                      placeholder="What did you observe?"
                      rows={6}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
            <Card.Footer>
              <div className="col text-center">
                <Button pill color="info" icon="upload">
                  Submit Report
                </Button>
              </div>
            </Card.Footer>
          </Form>
        </Card>
        </Grid.Col>
      </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default DataContribution;
