import React, { useState, useEffect, } from 'react';
import axiosInstance from '../../constants/axiosApi';
import { Link } from "react-router-dom";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import download from 'downloadjs';
import { 
  Page,
  Card,
  Grid,
  Table,
  Form,
  Button,
  StampCard,
  Badge,
  List,
} from 'tabler-react';

import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import Disclaimer from '../reusables/Disclaimer';
import { Image } from 'react-bootstrap';
import SiteWrapper from '../../SiteWrapper';

import { isAuthenticated } from '../../constants/authApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      flexWrap: 'wrap',
      height: theme.spacing(30),
      padding: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
      width: '170px',
    },
  }),
);


export default function DataCatalogPage() {
  const classes = useStyles();
	const [classification, setClassification] = useState({});
  const [datasetFiles, setDatasetFiles] = useState({});

	const [state, setState] = useState({
    topic: "",
  });

	const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const fetchDatasetClassification = async () => {
    const results = await axiosInstance.get(
      'api/datasets-classification/',
    );
    setClassification(results.data);
  };

  const fetchDatasets = async () => {
    const results = await axiosInstance.get('api/datasets/', {
      params: {
        dataset_classification: state.topic,
      }
    });
      setDatasetFiles(results.data);
  };

  useEffect(() => {
    fetchDatasets();
    fetchDatasetClassification();
  }, []);

  useEffect(() => {
    fetchDatasets();
  }, [state.topic]);

  const handleChangeTopic = (event) => {
    event.preventDefault();
    setState({topic: event.target.value });
  }

  const fetchDownloadLink = async (datasetfiles) => {
    const results = await axiosInstance.get(datasetfiles.file.url, {
      params: {
        responseType: 'blob',
      }      
    })
    .then(response => {
          const content = response.headers['content-type'];
          download(response.data, datasetfiles.file.name, content)
      })
    .catch(error => console.log(error));
  };
 
  return(
	  <SiteWrapper>
	  	<Page.Content>
		  	<Grid.Row>
		  		<Grid.Col lg={3}>
          <h4 style={{color: '#58AB81'}}>Dataset Types: ({classification.length})</h4>
            {Object.values(classification).map((classification, i) => {
            return(
              <React.Fragment key={i}>
                <Grid.Row>
                  <Card>
                    <Card.Body>
                      <Grid.Row>
                        <Grid.Col md={12}>
                          <Button link RootComponent="input" type="button" value={classification.topic} onClick={handleChangeTopic}>
                            <Typography variant="h5" gutterBottom style={{color: '#58AB81'}}>
                              {classification.topic}
                            </Typography>
                          </Button>
                          <Divider />
                          <br />		             
                          <Typography variant="caption" display="block" gutterBottom>
                            {classification.description}
                          </Typography>
                          <Chip size="small" label={classification.tags} />
                        </Grid.Col>
                      </Grid.Row>
                    </Card.Body>
                  </Card>
                </Grid.Row>
              </React.Fragment>
            )})}

		  		</Grid.Col>
		  		<Grid.Col lg={9}>
            <h4 style={{color: '#FF8575'}}>{datasetFiles.length} Dataset(s) Found:</h4>
            { Object.values(datasetFiles).map((datasetFiles, i) => {
              return(
                <React.Fragment key={i}>
                  <Paper elevation={3} className={classes.root}>
                    <Grid.Row>
                      <Grid.Col sm={9}>
                        <Grid.Row>
                          <Grid.Col sm={8}>
                            <dl>
                              <dt>Description:</dt>
                              <dd>
                                <Typography variant="body2" gutterBottom>
                                {datasetFiles.description}
                                </Typography>
                              </dd>
                              <dt>Date Created:</dt>
                              <dd><Typography variant="overline" display="block" gutterBottom>
                                {datasetFiles.date_created}
                              </Typography></dd>
                              <dt>Citation:</dt>
                              <dd><Typography variant="body2" display="block" gutterBottom>
                                {datasetFiles.citation}
                              </Typography></dd>
                          </dl>
                          </Grid.Col>    
                          <Grid.Col sm={4}>
                          <dl>
                            <dt>Organization:</dt>
                            <dd>
                              <Typography variant="body2" gutterBottom>
                              {datasetFiles.institution}
                              </Typography>
                            </dd>
                            <dt>Data Policy:</dt>
                            <dd>
                              <Typography variant="body2" display="block" gutterBottom>
                                {(datasetFiles.data_policy == "PUBLIC" || datasetFiles.data_policy == "CC-BY")
                                ?
                                <Badge color="success" className="mr-1">
                                  {datasetFiles.data_policy}
                                </Badge>
                                :
                                <Badge color="danger" className="mr-1">
                                  {datasetFiles.data_policy}
                                </Badge>
                                }
                              </Typography>
                            </dd>
                            <dt>File Type:</dt>
                            <dd><Typography variant="overline" display="block" gutterBottom>
                              <Chip size="small" label={datasetFiles.file.name.split('.').pop()} />
                            </Typography></dd>
                          </dl>
                        </Grid.Col>
                        </Grid.Row>
                      </Grid.Col>
                      <Grid.Col sm={3}>
                        { isAuthenticated()!=true && (datasetFiles.data_policy=="PRIVATE" || datasetFiles.data_policy=="RESTRICTED")
                          ?     
                          <Link to="/login">
                            <Tooltip title="Login" aria-label="login" placement="bottom-end">
                              <div>
                              <Button className={classes.button} pill color="info">Login</Button>
                              </div>
                            </Tooltip>
                          </Link>
                          :
                          <Tooltip title="Download" aria-label="download" placement="bottom-end">
                            <div>
                            <Button className={classes.button} pill color="info" onClick={() => {fetchDownloadLink(datasetFiles)}} target="_blank" download>Download ({datasetFiles.file.size}KB)</Button>
                            </div>
                          </Tooltip>
                        }
                      </Grid.Col>
                    </Grid.Row> 
                  </Paper>
                  <br />
                </React.Fragment>
              )
            })}
					</Grid.Col>
		  	</Grid.Row>
		  	<Grid.Row>
        	<Disclaimer />
      	</Grid.Row>
	  	</Page.Content>
	  </SiteWrapper>
  )
}