import React, { useState, useEffect, } from 'react';
import Divider from '@material-ui/core/Divider';
import axiosInstance from '../../constants/axiosApi';
import { Link } from "react-router-dom";
import { 
  Page,
  Card,
  Grid,
  Table,
  Form,
  Button,
  StampCard
} from 'tabler-react';

import Disclaimer from '../reusables/Disclaimer';
import { Image } from 'react-bootstrap';
import SiteWrapper from '../../SiteWrapper';

function DataCatalog() {
	const [classification, setClassification] = useState({});

	const [state, setState] = useState({
    name_ascending: false,
    name_descending: false,
    last_modified: false,
    search: ""
  });

	const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

	useEffect(() => {
    const fetchData = async () => {
      const results = await axiosInstance.get(
        'api/datasets-classification/',
      );
      setClassification(results.data);
    };
    fetchData();
  }, []);

  return(
	  <SiteWrapper>
	  	<Page.Content>
		  	<Grid.Row>
		  		<Grid.Col lg={3}>
			  		<Card>
		          <Card.Body>
		          	<Form.Group label="Order by:">
								  <Form.Radio
								    label="Name Ascending"
								    name="name_ascending"
								    value="name_ascending"
                  	checked={state.name_ascending}
                  	onChange={handleChange}
                  	disabled="True"
								  />
								  <Form.Radio
								    label="Name Descending"
								    name="name_descending"
								    value="name_descending"
								    checked={state.name_descending}
                  	onChange={handleChange}
                  	disabled="True"
								  />
								  <Form.Radio
								    label="Last Modified"
								    name="last_modified"
								    value="last_modified"
								    checked={state.last_modified}
                  	onChange={handleChange}
                  	disabled="True"
								  />
								</Form.Group>
								<Divider variant="middle" />
								<br />
								<Form.Group label="Organization">
								  <Form.Checkbox
								    label="BFAR"
								    name="example-radios"
								    value="option1"
								    disabled="True"
								  />
								  <Form.Checkbox
								    label="UP MSI"
								    name="example-radios"
								    value="option2"
								    disabled="True"
								  />
								</Form.Group>
					    </Card.Body>
			  		</Card>
		  		</Grid.Col>
		  		<Grid.Col lg={9}>
		  		<Card>
	          <Card.Body>
	          	<Form.Group>
							  <Form.Input
							    className="mb-3"
							    icon="search"
							    placeholder="Search Datasets..."
							    position="append"
							  />
							</Form.Group>
					  	<h4 style={{color: '#FF8575'}}>{classification.length} Dataset(s) Found:</h4>
              { Object.values(classification).map((classification, i) => {
                return(
                	<React.Fragment key={i}>
                	<br />
                	<Grid.Row>

                		<Grid.Col md={9}>
		                	<h4 style={{color: '#58AB81'}}>
		                		<Link to={`/datasets/${classification.topic}`}>
		                			{classification.topic}
		                		</Link>					             
		                	</h4>
		                  <h6>Description:</h6>
		                  <p>{classification.description}</p>
		                  <h6>Tags: {classification.tags}</h6>
	                  </Grid.Col>
	                  <Grid.Col md={3}>
		                </Grid.Col>
									</Grid.Row>
									<Divider variant="middle" />
                  </React.Fragment>
                )
              })}
				    </Card.Body>
		  		</Card>
					</Grid.Col>
		  	</Grid.Row>
		  	<Grid.Row>
        	<Disclaimer />
      	</Grid.Row>
	  	</Page.Content>
	  </SiteWrapper>
  )
}

export default DataCatalog;