import React, {PureComponent} from 'react';
import axiosInstance from '../../../constants/axiosApi';
import CsvDownloader from 'react-csv-downloader';
import Divider from '@material-ui/core/Divider';

import {
  Page,
  Grid,
  Container,
  Card,
  Form,
  Button,
} from 'tabler-react';

import MapMonitoring from './MapMonitoring';
import AvailableData from './AvailableData';
import { AvailableDataDownload } from './DownloadPanel/AvailableDataDownload';
import DownloadPanel from './DownloadPanel/DownloadPanel';
import Disclaimer from '../../reusables/Disclaimer';
import SiteWrapper from '../../../SiteWrapper';

function HabDataPortal() {

  return (
    <SiteWrapper>
      <Page.Content title="Data Viewer and Downloader">
        <Grid.Row>
          <Form.Group>
            <Form.StaticText>
            Welcome to the HABHub Site Monitoring and Data Downloader. This portal contains data on water quality parameters and HAB related information such as phytoplankton density and shellfish toxicity in different parts of the Philippines. These data come from different contributors and are from archived project and monitoring data, ongoing field campaigns, and experimental sensors deployed in a number of sites. Information on appropriate attribution will be provided as part of the dataset description in the data catalog and download.
            </Form.StaticText>
          </Form.Group>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={8}>
            <Container>
              <MapMonitoring />
            </Container>
          </Grid.Col>
          <Grid.Col lg={4}>
            {/* <AvailableData /> */}
            <DownloadPanel />
            {/* <AvailableDataDownload /> */}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Form.Group>
            <Form.StaticText>
            Want to contribute to HAB Hub?
            Please contact <a href="mailto:habhub@msi.upd.edu.ph">habhub@msi.upd.edu.ph</a> for more information.
            </Form.StaticText>
          </Form.Group>
        </Grid.Row>
        <Grid.Row>
          <Disclaimer />
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default HabDataPortal;
