import React from "react";
import {
  Page,
  Grid,
  Card,
} from 'tabler-react';
import Image from 'react-bootstrap/Image'

import SiteWrapper from "../../SiteWrapper";

function CoastsAndHabs() {
  return (
  	<SiteWrapper>
  		<Page.Content>
  			<Grid.Row>
	       		<Grid.Col lg={12}>
	       			<Card statusColor="green">
	       				<Card.Header>
                            <Card.Title><b>COASTS AND HABS</b></Card.Title>
                        </Card.Header>
		       			<Card.Body>
			            		<Image src="./images/coasts-and-habs.png" />
			            		<br /><br />
			            		<div id="subtopic1" className="container-fluid">
				            	<h3 id="hab">Partners</h3>
											<p>
												An important goal of COASTS is to empower coastal communities through greater access to science and technology. Fostering a dynamic relationship with governance institutions and academic community can help craft an effective people-centered management framework to manage coastal resources and maintain a healthy marine ecosystem. These will be the key to reducing the underlying disaster risks and enable communities to design an effective people-centered early warning system for HABS.											</p>
											<p>
												We have partnered with local coastal communities in Bolinao, Pangasinan; Sapian, Capiz; Jiabong, Samar and their Local Government Units. We are also joined by central and regional offices of the Bureau of Fisheries and Aquatic Resources (BFAR) and the SUCs, Palawan State University (PSU) and University of the Philippines Tacloban (UPVTC). These engagements were facilitated and grown through the efforts of Devralin Lagos (College of Social Work and Community Development, UP Diliman) and Harianne Gasmen (Phivolcs), and Dr. Aletta T. Yñiguez, Rica Dungog, Narod Rodrigo Eco, Julius Gopez, and Janine Mae Novelero from the Marine Science Institute, University of the Philippines Diliman.
											</p>
											<h3>Efforts</h3>
											<p>
												Our activities as a network were focused on a two-way learning process with our community partners. These are mostly shellfish farmers, gleaners and fishermen whose livelihood greatly depend on fisheries. We have conducted several risk assessment workshops and community plannings to understand how they deal with the HAB occurrences in their respective areas. Recently, our partners in the community also attended the 11th EastHAB and 4th PhilHAB Symposium and Conference where they presented their take on HABS in their communities to members of the academe, government agencies, local government units, private organizations and scientists. In addition, our partners in the SUCs weekly monitor the key sites in Tacloban and Palawan.
											</p>
										</div>
						</Card.Body>
	       			</Card>
	       		</Grid.Col>
       	</Grid.Row>
  		</Page.Content>

  	</SiteWrapper>
  )
}

export default CoastsAndHabs;
