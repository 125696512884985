import axiosInstance, { setNewHeaders } from './axiosApi';

export async function signUp(email, password, first_name, last_name, institution, position) {
  const response = await axiosInstance.post("/user/create/", {
    email,
    password,
    first_name,
    last_name,
    institution,
    position
  });
  return response;
}

export async function obtainToken(email, password) {
  const response = await axiosInstance.post("/token/obtain/", {
    email,
    password,
  });
  setNewHeaders(response);
  return response;
}

export async function refreshToken(refresh) {
  const response = await axiosInstance.post("/token/refresh/", {
    refresh,
  });
  setNewHeaders(response);
  return response;
}

// eslint-disable-next-line
export async function logout(accessToken) {
  try {
        const response = await axiosInstance.post('/blacklist/', {
            "refresh_token": localStorage.getItem("refresh_token")
        });
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        axiosInstance.defaults.headers['Authorization'] = null;
        /*this.setState({loginStatus: false});*/
        return response;
	    }
	    catch (e) {
	        console.log(e);
	    }
}

export const isAuthenticated = () => {
  const token = localStorage.getItem("access_token");
  return !!token;
};