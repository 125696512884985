import React from 'react';

import {
  Page,
  Grid,
  Card,
} from 'tabler-react';

import SiteWrapper from '../../SiteWrapper';
import {Link} from "react-router-dom";

function AboutTheOrganization() {
  return (
  	<SiteWrapper>
  		<Page.Content>
  			<Grid.Row>
	       		<Grid.Col lg={9}>
	       			<Card statusColor="green">
	       				<Card.Header>
                    <Card.Title><b>ABOUT THE ORGANIZATION</b></Card.Title>
                </Card.Header>
		       			<Card.Body>
					        <div className="container-fluid">
				        		<p><a href="/coasts-and-habs">COASTS</a> or Community Alliance for the Sustainability of our Threatened Seas hopes to help address the risks from harmful algal blooms and pollution by providing ways to more rapidly detect the conditions and organisms leading to HABs and eventually develop an early-warning system that can be used by shellfish and fish farmers and government agencies.</p>

				        		<p>Currently, there are three pilot sites in Samar, Capiz and Bolinao, which were chosen based on their different HAB types and their importance in contributing to seafood production. At each site, local fishers, other stakeholders and government units are engaged in various ways:</p>
				        		<ul>
								  	<li>Discussions to better understand their risks from HABs</li>
								  	<li>Trainings for increased awareness about HABs, and to develop their capacity for monitoring and response</li>
								  	<li>Active participation in monitoring water quality and HABs</li>
							  	</ul>
							  	<p>Through these efforts, the COASTS program hopes not only to help mitigate the harmful effects of HABs but also to build a network of partners working towards improving food safety, livelihood security, and ultimately ensuring the quality of our waters.</p>
							</div>
						</Card.Body>
	       			</Card>
	       		</Grid.Col>
	       		<Grid.Col lg={3}>
		       		<div className="sticky-top" id="abouthab-cards">
								<Card title="About:">
						      <Card.Body>
						      	<table className="ml-5 col text-md-left">
						      		<tr><Link to="/about-the-project" >The Project</Link></tr>
						        	<tr><Link to="/about-the-organization" >The Organization</Link></tr>
						        	<tr><Link to="/project-partners" >Project Partners</Link></tr>
						        	<tr><Link to="/contact-us" >Contact Us</Link></tr>
						        </table>
						      </Card.Body>
						    </Card>
							</div>
	       		</Grid.Col>
       	</Grid.Row>
  		</Page.Content>
  	</SiteWrapper>
  );
}

export default AboutTheOrganization;
